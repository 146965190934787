import React, { useState, useEffect } from "react";
import { auth, googleProvider, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  collection,
  getDocs,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Users from "./users.js";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitch from "../components/toggle.js";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Line,
  ResponsiveContainer,
  LineChart,
} from "recharts";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//../src/components/userPrompt.js";
import thumbup from "..//../src/thumbup.svg";
import thumbdown from "..//../src/thumbdown.svg";
import rightarrow from "..//../src/right_arrow.svg";
import { Spinner } from "react-bootstrap";
import { signOut } from "firebase/auth";

function PartnerPortal() {
  const { currentUser } = useAuth(); // Using a custom hook to get the current user
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyRole, setCompanyRole] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyComune, setCompanyComune] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("");
  const [companyPiva, setCompanyPiva] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [currentuserID, setCurrentUserID] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [trustPilot, setTrustPilot] = useState(0);
  const [cw, setCw] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [services, setServices] = useState([]);
  const [incentives, setIncentives] = useState([]);
  const [phone, setPhone] = useState("");
  const [logo, setLogo] = useState("");

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  useEffect(() => {
    async function fetchUserData() {
      if (currentUser && currentUser.email) {
        try {
          const usersCollectionRef = collection(db, "users");
          const q = query(
            usersCollectionRef,
            where("email", "==", currentUser.email)
          );
          const querySnapshot = await getDocs(q);
    
          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();
    
            // Check the role of the user
            if (userData.role === "partner") {
              // Set state for partner-specific fields
              setFirstName(userData.firstName || "N/A");
              setLastName(userData.lastName || "N/A");
              setCompanyEmail(userData.email || "N/A");
              setRegione(userData.regione || "N/A");
              setServices(userData.services || []);
              setIncentives(userData.incentives || []);
              setCompanyRole(userData.role || "N/A");
              setTrustPilot(userData.trustPilot || "N/A");
              setPhone(userData.phone || "N/A");
              setLogo(userData.logo || "");
            } else {
              // Set state for non-partner users
              setAtecoL3(userData.companyAteco || "");
              setRegione(userData.regione || "");
              setFirstName(userData.firstName || "");
              setLastName(userData.lastName || "");
              setCompanyRole(userData.role || "");
              setCompanyEmail(userData.email || "");
              setCompanyComune(userData.comune || "");
              setCompanyWebsite(userData.website || "");
              setCompanyPiva(userData.piva || "");
              setCompanyName(userData.companyName || "");
              setCompanySize(userData.companySize || "");
              setCompanyEmployees(userData.companyEmployees || "");
              setCompanyTurnover(userData.companyTurnover || 0);
    
              // Fetch service data if relevant
              if (userData.service) {
                await fetchServiceData(userData.service);
              }
            }
    
            setCurrentUserID(userDoc.id); // Set the user ID regardless of role
          } else {
            console.log("No user data found for email:", currentUser.email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    }
    

    // Function to fetch service data
    async function fetchServiceData(serviceId) {
      try {
        const serviceDocRef = doc(db, "services", serviceId);
        const serviceDoc = await getDoc(serviceDocRef);
        if (serviceDoc.exists()) {
          setServices([serviceDoc.data()]);
        } else {
          console.log("No service found with ID:", serviceId);
        }
      } catch (error) {
        console.error("Error fetching service data:", error);
      }
    }

    fetchUserData();
  }, [currentUser]);

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const extractBase = (ateco) => {
    // Regex to capture two digits before and one or two digits after the decimal point
    const numericalPartMatch = ateco.match(/^(\d{2}\.\d{1,2})/);
    let numericalPart = "";

    if (numericalPartMatch) {
      numericalPart = numericalPartMatch[1];
      const decimalParts = numericalPart.split(".");

      // Ensure two decimal places by padding with a zero if necessary
      if (decimalParts[1].length === 1) {
        numericalPart = `${decimalParts[0]}.${decimalParts[1]}0`;
      }
    }

    return numericalPart;
  };

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const navigate = useNavigate();
  const logout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (err) {
      console.error("Error logging out:", err);
    }
  };
  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container">
      {!isAuthenticated && <UserPromptModal />}
  
      <div className="searchy-containered">
        <div>
          <div className="agent2-containers">
            <div className="incentivy-container">
              {/* Check if the user is a partner */}
              {companyRole === "partner" ? (
                <div>
                  {/* Partner-specific layout */}
                  <h1
                    style={{
                      margin: "10px 10px 10px 10px",
                      color: "white",
                      textAlign: "center",
                      fontSize: "28px",
                      lineHeight: "2rem",
                    }}
                  >
                   {capitalizeFirstLetter(firstName)}{" "}
                    {capitalizeFirstLetter(lastName)}
                  </h1>
                  {logo && (
      <div style={{ textAlign: "center", margin: "20px 0" }}>
        <img
          src={logo}
          alt={`${firstName} ${lastName} Logo`}
          style={{
            maxWidth: "200px",
            height: "auto",
            borderRadius: "8px",
            border: "2px solid white",
          }}
        />
      </div>
    )}
                  <h2
                    style={{
                      margin: "10px 10px 10px 10px",
                      color: "white",
                      textAlign: "center",
                      fontSize: "22px",
                      lineHeight: "2rem",
                      backgroundColor: "#333",
                      padding: "15px",
                      borderRadius: "8px",
                      margin: "10px",
                    }}
                  >
                    <span
                      style={{
                        color: "white",
                        fontSize: "16px",
                        lineHeight: "2rem",
                        fontWeight: "normal",
                      }}
                    >
                      Email: <span style={{ color: "#FF5758" }}>{companyEmail}</span>
                      <br />
                      Telefono: <span style={{ color: "#FF5758" }}>{phone}</span>
                      <br />
                      Regione: <span style={{ color: "#FF5758" }}>{regione}</span>
                      <h3 style={{ color: "white" }}>Incentives:</h3>
                        {incentives && incentives.length > 0 ? (
                          <ul style={{ color: "#FF5758" }}>
                            {incentives.map((incentive, index) => (
                              <li key={index}>{incentive}</li>
                            ))}
                          </ul>
                        ) : (
                          <p style={{ color: "#FF5758" }}>No incentives available.</p>
                        )}
                      <br />
                      Services:
                      <ul>
                        {services.length > 0 ? (
                          services.map((service, index) => (
                            <li key={index} style={{ color: "#FF5758" }}>
                              {service}
                            </li>
                          ))
                        ) : (
                          <li>No services available</li>
                        )}
                      </ul>
                      <br />
                      TrustPilot Rating: <span style={{ color: "#FF5758" }}>{trustPilot}</span>
                    </span>
                  </h2>
                </div>
              ) : (
                <div>
                  {/* Default layout for other roles */}
                  <h1
                    style={{
                      margin: "10px 10px 10px 10px",
                      color: "white",
                      textAlign: "center",
                      fontSize: "28px",
                      lineHeight: "2rem",
                    }}
                  >
                    {companyName
                      ? companyName
                      : "No user data available or user not signed in."}
                  </h1>
                  <h2
                    style={{
                      margin: "10px 10px 10px 10px",
                      color: "white",
                      textAlign: "center",
                      fontSize: "22px",
                      lineHeight: "2rem",
                      backgroundColor: "#333",
                      padding: "15px",
                      borderRadius: "8px",
                      margin: "10px",
                    }}
                  >
                    {firstName
                      ? capitalizeFirstLetter(firstName)
                      : "No user data available or user not signed in."}{" "}
                    {lastName
                      ? capitalizeFirstLetter(lastName)
                      : "No user data available or user not signed in."}
                    <span
                      style={{
                        color: "white",
                        fontSize: "16px",
                        lineHeight: "2rem",
                        fontWeight: "normal",
                      }}
                    >
                      <br />
                      Role: <span style={{ color: "#FF5758" }}>{companyRole}</span>
                      <br />
                      Email: <span style={{ color: "#FF5758" }}>{companyEmail}</span>
                      <br />
                      Partita Iva: <span style={{ color: "#FF5758" }}>{companyPiva}</span>
                      <br />
                      Regione: <span style={{ color: "#FF5758" }}>{regione}</span>
                      <br />
                      Comune: <span style={{ color: "#FF5758" }}>{companyComune}</span>
                      <br />
                      Ateco:{" "}
                      <span style={{ color: "#FF5758" }}>{extractBase(atecoL3)}</span>
                      <br />
                      Dimensione:{" "}
                      <span style={{ color: "#FF5758" }}>{companySize}</span>
                      <br />
                      Turnover:{" "}
                      <span style={{ color: "#FF5758" }}>{companyTurnover}</span>
                      <br />
                      Dipendenti:{" "}
                      <span style={{ color: "#FF5758" }}>{companyEmployees}</span>
                      <br />
                      Website:{" "}
                      <span style={{ color: "#FF5758" }}>{companyWebsite}</span>
                      <br />
                    </span>
                    <div
                      className="service-container"
                      style={{ marginTop: "20px", color: "white" }}
                    >
                      <h3 style={{ textAlign: "center", fontSize: "24px" }}>
                        Current Services
                      </h3>
                      {services.length > 0 ? (
                        services.map((service, index) => (
                          <div
                            key={index}
                            className="service-card"
                            style={{
                              backgroundColor: "#333",
                              padding: "15px",
                              borderRadius: "8px",
                              margin: "10px",
                              fontSize: "16px",
                            }}
                          >
                            <h4>
                              <span style={{ color: "#FF5758" }}>{service.serviceName}</span>
                            </h4>
                            <p>{service.serviceDescription}</p>
                          </div>
                        ))
                      ) : (
                        <p>No services available.</p>
                      )}
                    </div>
                  </h2>
                </div>
              )}
              <button
                onClick={logout}
                className="logout-button"
                style={{ marginLeft: "3px" }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
}

export default PartnerPortal;
