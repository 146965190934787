// src/Placeholder.js
import React from 'react';

const PlaceholderReturn = () => (
  <div style={{ textAlign: 'center', padding: '50px' }}>
    <h2>Page Not Found</h2>
    <p>The page you are looking for does not exist.</p>
  </div>
);

export default PlaceholderReturn;
