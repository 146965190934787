import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../../src/config/firebase.js"; // Adjust your Firebase imports
import psuimg from "../../src/postsignupimg2.png";
import "./allCSS.css";

export default function SuccessNotification() {
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          // Fetch the user's document from Firestore
          const userDoc = await getDoc(doc(db, "users", user.uid));
          if (userDoc.exists()) {
            const { role } = userDoc.data(); // Assume `role` field exists
            setUserRole(role);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error("Error fetching user role:", error);
        }
      } else {
        console.log("User is signed out.");
      }
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const handleProceed = () => {
    window.scrollTo(0, 0);
    if (userRole === "azienda") {
      navigate("/search"); // Navigate to the search page for 'azienda'
    } else {
      navigate("/searchpros"); // Navigate to the searchpros page for others
    }
  };

  return (
    <div className="success-notification-container">
      <div className="success-notification-content">
        <h1 className="success-header">🎉 Congratulazioni per l'Upgrade!</h1>
        <img src={psuimg} alt="Success" className="success-image" />
        <p className="success-message">
          Siamo entusiasti di averti con noi! Ora hai accesso a tutti gli
          strumenti avanzati che Incentivato ti offre per trovare e ottenere
          incentivi. Sei pronto a sfruttare tutte le nuove opportunità?
          <br />
          <br />
          Inizia subito a esplorare e raggiungi i tuoi obiettivi con noi!
        </p>
        <button onClick={handleProceed} className="proceed-button-successpay">
          Procedi alla Ricerca
        </button>
      </div>
    </div>
  );
}
