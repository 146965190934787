import React, { useState, useEffect } from "react";
import { db } from "../config/firebase.js"; // Firestore configuration
import { collection, query, where, getDocs } from "firebase/firestore";
//import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const IncentiveGuessGame = () => {
  const [formVisible, setFormVisible] = useState(false); 
  const [regionList, setRegionList] = useState([]);
  const [atecoList, setAtecoList] = useState([]);
  const [regione, setRegione] = useState("");
  const [ateco, setAteco] = useState("");
  const [guess, setGuess] = useState("");
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [carouselItems, setCarouselItems] = useState([]);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  
  const navigate = useNavigate();

  const toggleForm = () => {
    setFormVisible(!formVisible); // toggle form visibility
  };

  const handleRegisterRedirect = () => {
    navigate("/login");
  };

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const regionCollection = collection(db, "region_incentives");
        const regionSnapshot = await getDocs(regionCollection);
        const regions = regionSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setRegionList(regions);
      } catch (err) {
        console.error("Error fetching regions:", err);
      }
    };
    fetchRegions();
  }, []);

  useEffect(() => {
    const fetchAteco = async () => {
      try {
        const atecoCollection = collection(db, "ateco_incentives");
        const atecoSnapshot = await getDocs(atecoCollection);
        const atecos = atecoSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAtecoList(atecos);
      } catch (err) {
        console.error("Error fetching ATECO codes:", err);
      }
    };
    fetchAteco();
  }, []);

  useEffect(() => {
    const fetchCarouselItems = async () => {
      try {
        const incentivesCollection = collection(db, "incentivi");
        const incentivesSnapshot = await getDocs(incentivesCollection);
        const allItems = incentivesSnapshot.docs.map((doc) => ({
          id: doc.id,
          title: doc.data().Titolo,
          imageUrl: doc.data().urlImage,
        }));
        const shuffledItems = allItems.sort(() => 0.5 - Math.random()).slice(0, 4);
        setCarouselItems(shuffledItems);
      } catch (err) {
        console.error("Error fetching carousel items:", err);
      }
    };
    fetchCarouselItems();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemIndex((prevIndex) =>
        prevIndex === carouselItems.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);
    return () => clearInterval(interval);
  }, [carouselItems]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setResult(null);
    setLoading(true);

    try {
      const regionDocRef = collection(db, "region_incentives");
      const regionQuery = query(regionDocRef, where("__name__", "==", regione));
      const regionSnapshot = await getDocs(regionQuery);

      let regionIncentives = new Set();
      if (!regionSnapshot.empty) {
        const regionData = regionSnapshot.docs[0].data();
        if (regionData.incentives) {
          regionIncentives = new Set(regionData.incentives);
        }
      }

      const atecoDocRef = collection(db, "ateco_incentives");
      const atecoQuery = query(atecoDocRef, where("__name__", "==", ateco));
      const atecoSnapshot = await getDocs(atecoQuery);

      let atecoIncentives = new Set();
      if (!atecoSnapshot.empty) {
        const atecoData = atecoSnapshot.docs[0].data();
        if (atecoData.incentives) {
          atecoIncentives = new Set(atecoData.incentives);
        }
      }

      const matchingIncentives = [...regionIncentives].filter((incentive) =>
        atecoIncentives.has(incentive)
      );
      const numberOfIncentives = matchingIncentives.length;

      const incentivoText = numberOfIncentives === 1 ? "incentivo" : "incentivi";
      const difference = Math.abs(parseInt(guess) - numberOfIncentives);
      const percentageDiff = (difference / numberOfIncentives) * 100;

      // Updated message logic with ±5% range check
      const resultText = parseInt(guess) === numberOfIncentives
        ? `🎉 Congratulazioni! Hai indovinato il numero corretto di ${incentivoText}! 🎉`
        : percentageDiff <= 5
        ? `Hai quasi trovato! Pensavi ${guess}, ma in realtà ci sono <span class="highlighted-number">${numberOfIncentives}</span> ${incentivoText}. Avrai già una buona visibilità dei incentivi!`
        : parseInt(guess) < numberOfIncentives
        ? `Pensavi ${guess}, ma in realtà ci sono ben <span class="highlighted-number">${numberOfIncentives}</span> ${incentivoText} attivi! 🥳 Registrati per vedere la lista completa!`
        : `Wouah sei super ottimista! Pensavi ${guess}, ma ci sono solo <span class="highlighted-number">${numberOfIncentives}</span> ${incentivoText}! 😄 Registrati per vedere la lista completa!`;

      setResult(resultText);
    } catch (err) {
      setError("Errore durante il recupero dei dati. Riprova più tardi.");
      console.error("Error fetching incentives: ", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="incentive-guess-game">
      {/* Button to reveal the form */}
      <button className="indovina-button" onClick={toggleForm}>
       Indovina i tuoi Incentivi!
      </button>

      {/* Conditionally render the form based on formVisible state */}
      {formVisible && (
        <form onSubmit={handleSubmit} className="guess-form">
          <div>
            <label>Regione: </label>
            <select value={regione} onChange={(e) => setRegione(e.target.value)} required>
              <option value="">Seleziona una regione</option>
              {regionList.map((region) => (
                <option key={region.id} value={region.id}>
                  {region.id}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>Codice ATECO: </label>
            <select value={ateco} onChange={(e) => setAteco(e.target.value)} required>
              <option value="">Seleziona un codice ATECO</option>
              {atecoList.map((ateco) => (
                <option key={ateco.id} value={ateco.id}>
                  {ateco.id}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label>La tua stima: </label>
            <input type="number" value={guess} onChange={(e) => setGuess(e.target.value)} required />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? "Calcolo in corso..." : "Invia la tua stima"}
          </button>
          {carouselItems.length > 0 && (
        <div className="carousel">
          <img src={carouselItems[currentItemIndex]?.imageUrl} alt="Incentive" className="carousel-image" />
          <p className="carousel-title">{carouselItems[currentItemIndex]?.title}</p>
        </div>
      )}

      <button className="register-button" onClick={handleRegisterRedirect}>
        Registrati qui!
      </button>
        </form>
      )}

      {result && <p className="result-text" dangerouslySetInnerHTML={{ __html: result }} />}
      {error && <p className="error">{error}</p>}

    </div>
  );
};


export default IncentiveGuessGame;