import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import representativeImage from '../become_partner.png';

const DiventaPartner = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    profession: '',
    region: '',
    services: '',
    message: '',
  });

  const [submissionStatus, setSubmissionStatus] = useState('');
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const aggregatedData = `
      Nome: ${formData.name}
      Professione: ${formData.profession}
      Regione: ${formData.region}
      Servizi Offerti: ${formData.services}
      Messaggio: ${formData.message}
    `.trim();

    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sender_email: formData.email,
          incentive_id: aggregatedData,
        }),
        credentials: 'include',
      });

      const result = await response.json();

      if (response.ok) {
        setSubmissionStatus('Email sent successfully.');
        setTimeout(() => {
          navigate('/');
          window.scrollTo(0, 0); // Scroll to the top of the home page
        }, 2000); // Redirects to homepage after 2 seconds
      } else {
        setSubmissionStatus(result.error || 'An error occurred.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setSubmissionStatus('Failed to send email. Please try again later.');
    }
  };

  return (
    <div className="partner-container">
      <div className="hero-section">
        <div className="text-section">
          <h1>Espandi il Tuo Business con Incentivato</h1>
          <p className="intro-text">
            Diventa un partner di fiducia e accedi alle migliori opportunità di finanza agevolata. Siamo alla ricerca di consulenti, commercialisti, e professionisti certificati per unirsi alla nostra rete e supportare le PMI nel loro percorso di crescita.
          </p>
          <p className="cta-message">Compila il modulo qui sotto per unirti alla nostra rete di professionisti.</p>
        </div>
        <div className="image-section">
          <img src={representativeImage} alt="Rappresentazione del Partner" />
        </div>
      </div>

      <div className="form-section">
        <h2>Diventa Partner</h2>
        <p>
          Se sei un <span className="highlight">consulente in finanza agevolata</span>, <span className="highlight">commercialista</span>, <span className="highlight">notaio</span> o un <span className="highlight">professionista certificato</span>, compila il modulo per proporre le tue competenze e diventare un partner selezionato.
        </p>
        <form onSubmit={handleSubmit} className="partner-form">
          <label>Nome:<input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Nome Cognome" required /></label>
          <label>Email:<input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="your preferred email" required /></label>
          <label>Professione:<select name="profession" value={formData.profession} onChange={handleChange} required>
            <option value="">Seleziona la tua professione</option>
            <option value="consulente">Consulente in Finanza Agevolata</option>
            <option value="commercialista">Commercialista</option>
            <option value="notaio">Notaio</option>
            <option value="altro">Altro</option>
          </select></label>
          <label>Regione:<input type="text" name="region" value={formData.region} onChange={handleChange} placeholder="Lombardia" required /></label>
          <label>Servizi Offerti:<textarea name="services" value={formData.services} onChange={handleChange} placeholder="Descrivi i servizi che offri" required /></label>
          <label>Messaggio:<textarea name="message" value={formData.message} onChange={handleChange} placeholder="Inserisci un messaggio facoltativo" /></label>
          <button type="submit">Invia la tua candidatura</button>
        </form>
        {submissionStatus && <p className="submission-status">{submissionStatus}</p>}
      </div>
    </div>
  );
};

export default DiventaPartner;
