import { HelmetProvider, Helmet } from "react-helmet-async";

function NotFound() {
  return (
    <div>
      <HelmetProvider>
        <title>404 - Page Not Found</title>
        <meta name="robots" content="noindex" />
      </HelmetProvider>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  );
}

export default NotFound;
