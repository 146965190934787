///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "..//..//src/config/firebase.js";
import {
  getDocs,
  getDoc,
  collection,
  addDoc,
  getFirestore,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//..//src/components/userPrompt.js";
import App from "..//App.js";
import { useParams } from "react-router-dom";
import IncentiveSearch from "./agent2.js";
import Typewriter from "..//components/Typewriter.js";
import SSEDisplay from "..//components/SSEDisplay.js";
import renderCriteria from "..//components/criteria.js";
import RatingComponent from "..//components/RatingComponent.js";
//import LongPollDisplay from '..//components/LongPolling.js';
import DOMPurify from "dompurify";
import clientlogo from "..//../src/technosida.png";
import { useAuth } from "../../src/components/auth-email.js";

function Goals() {
  const [goalInput, setGoalInput] = useState(""); // State to hold input from the user
  const [goals, setGoals] = useState([]); // State to store goals fetched from Firebase
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  //console.log(threadId); // This should log the actual threadId from the URL
  const navigate = useNavigate();
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const [lastVisible, setLastVisible] = useState(null);
  const location = useLocation();
  const { title } = location.state || { title: "Default Title" };
  const { assistant, threadId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [document, setDocument] = useState(null);
  const [submissionSource, setSubmissionSource] = useState("manual");
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [atecoL3, setAtecoL3] = useState("");
  const [companyAteco, setCompanyAteco] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [piva, setPiva] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [currentuserID, setCurrentUserID] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState("");
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [shouldRerender, setShouldRerender] = useState(false);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [shouldStartConversation, setShouldStartConversation] = useState(false);
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [selectedPivaData, setSelectedPivaData] = useState({
    companyName: "",
    companySize: "",
    companyTurnover: "",
    companyAteco: "",
    regione: "",
    piva: "",
    docName: "",
    currentuserID: ""
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsAuthenticated(!!user);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  /*get user data & ids*/
  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  async function fetchUserData() {
    if (currentUser && currentUser.email) {
      try {
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const userDoc = querySnapshot.docs[0];
          setAtecoL3(userData.companyAteco);
          setRegione(userData.regione);

          /*  await sendUserInfo(
              userData.regione,
              userData.companySize,
              userData.companyTurnover,
              userData.companyEmployees,
              userData.companyAteco,
            ); */

          setFirstName(userData.firstName);
          setCurrentUserID(userDoc.id);
          setPiva(userDoc.piva);
          setCompanyName(userData.companyName);
          setCompanySize(userData.companySize);
          setCompanyEmployees(userData.companyEmployees);
          setCompanyTurnover(userData.companyTurnover);
          setIsUserDataLoaded(true);
          setCompanyAteco(userData.companyAteco);

          //console.log("Selected PIVA Data set to:", {CurrentUserID:userDoc.id});
          setSelectedPivaData({
            companyName: userData.companyName,
            companySize: userData.companySize,
            companyTurnover: userData.companyTurnover,
            companyAteco: userData.companyAteco,
            regione: userData.regione,
            piva: userData.piva,
            docName: userDoc.id,
          });

/*           console.log("Selected PIVA Data set to:", {
              companyName: userData.companyName,
              companySize: userData.companySize,
              companyTurnover: userData.companyTurnover,
              companyAteco: userData.companyAteco,
              regione: userData.regione,
              piva: userData.piva,
              docName: userData.id,
            }); */
        } else {
          console.log("No user data found for email:", currentUser.email);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      console.log("currentUser is not defined yet");
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.email) {
      fetchUserData();
    }
  }, [currentUser]);

  useEffect(() => {
    if (selectedPivaData && selectedPivaData.docName) {
      fetchGoals();
    }
  }, [selectedPivaData]);

  /////////////////////////////////////////////////////////////////////////////////////////////////
  //////////* auth*/

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////////////
  /////message back and forth logic

  const [isLoading, setIsLoading] = useState(false);

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////GOALS

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        const db = getFirestore();
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );

        try {
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();

            // Fetch goals for the user
            fetchGoals(userDoc.id);
          } else {
            console.log("No user data found for email:", currentUser.email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    if (currentUser && currentUser.email) {
      fetchUserData();
    }
  }, [currentUser]); // This useEffect will run every time the currentUser changes

  // Function to fetch goals for a given user ID
  const fetchGoals = async (userId) => {
    if (!userId) {
      console.error("User ID is undefined");
      return;
    }

    const db = getFirestore();
    const userGoalsRef = doc(db, "goals", userId); // Only create if userId is defined

    try {
      const docSnapshot = await getDoc(userGoalsRef);

      if (docSnapshot.exists()) {
        const goalsData = docSnapshot.data()?.goals || [];
        setGoals(goalsData);
      } else {
        console.log("No goals found for user:", userId);
        setGoals([]);
      }
    } catch (error) {
      console.error("Error fetching goals:", error);
    }
  };

  // Function to handle adding a new goal
  const handleAddGoal = async (userId, newGoal) => {
    const db = getFirestore();
    const docRef = doc(db, "goals", userId); // Reference to the user's goals document

    try {
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        // If the document exists, add the new goal to the goals array
        await updateDoc(docRef, {
          goals: arrayUnion(newGoal), // Use arrayUnion to add the new goal to the existing goals
        });
        console.log("Goal added successfully");
      } else {
        // If the document doesn't exist, create it with the first goal
        await setDoc(docRef, { goals: [newGoal] });
        console.log("Document created and goal added successfully");
      }

      // Re-fetch goals after adding a new one
      fetchGoals(userId); // Ensure we fetch the updated goals after adding a new one
    } catch (error) {
      console.error("Error adding goal:", error);
    }
  };

  const handleRemoveGoal = async (goal) => {
    const db = getFirestore();
    const docRef = doc(db, "goals", userId); // Reference to the user's goals document

    try {
      await updateDoc(docRef, {
        goals: arrayRemove(goal), // Remove the goal from the goals array
      });
      console.log("Goal removed successfully");

      // Update the state to reflect the change
      setGoals((prevGoals) => prevGoals.filter((g) => g !== goal));
      setHasChanges(true);
    } catch (error) {
      console.error("Error removing goal:", error);
    }
  };

  const [isGoalInputVisible, setIsGoalInputVisible] = useState(false);

  ////////////////////////////////////////////////////////////////////////////////////////

  // const sendPivaToBackendforConfirm = async (piva, docId) => {
  //   console.log("Sending piva to backend:", piva);
  
  //   const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  
  //   setIsLoading(true); // Start loading
  
  //   try {
  //     const payload = { piva, docId };
  //     const response = await fetch(`${API_BASE_URL}/api/pivaconfirm`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(payload),
  //     });
  
  //     if (!response.ok) {
  //       // Attempt to parse error message from response
  //       const errorData = await response.json();
  //       throw new Error(errorData.message || "Network response was not ok");
  //     }
  
  //     const responseData = await response.json();
  //     console.log("Backend process triggered successfully:", responseData);
  
  //     if (
  //       window.confirm(
  //         "l'agente ha mappato con successo! Clicca OK per passare alla pagina di ricerca."
  //       )
  //     ) {
  //       navigate("/search"); 
  //       window.scrollTo(0, 0);
  //     }
  //   } catch (error) {
  //     console.error("Error triggering backend process:", error);
  //     alert(`Error: ${error.message}`);
  //   } finally {
  //     setIsLoading(false); // Stop loading
  //   }
  // };


  const sendPivaToBackendforConfirm = async (piva, docId) => {
    //console.log("Sending piva to backend:", piva);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    setIsLoading(true); // Start loading

    try {
      const payload = { piva, docId };
      const response = await fetch(`${API_BASE_URL}/api/pivaconfirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        // Attempt to parse error message from response
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      const responseData = await response.json();
      // console.log("Backend process triggered successfully:", responseData);
      // alert(
      //   "l'agente ha mappato con successo i tuoi nuovi obiettivi per gli incentivi!"
      // );
    } catch (error) {
      console.error("Error triggering backend process:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setIsLoading(false); // Stop loading
      navigate("/search"); 
    }
  };
  

  const processGoals = async () => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${API_BASE_URL}/api/processgoals`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ piva: selectedPivaData.piva }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || "Failed to process goals");
      }
  
      //console.log("Goals processed successfully:", data);
      alert("Obiettivi elaborati con successo!");
    } catch (error) {
      console.error("Error processing goals:", error);
    }
  };

  const handleButtonClick = async (piva, docName) => {
    if (!piva) {
      alert("PIVA is missing");
      return;
    }
  
    if (!docName) {
      console.warn("docName is undefined, using fallback value.");
      docName = "defaultDocName"; // Provide a default or fallback value
    }
  
    try {
      // Trigger the first function with piva and docName
      await sendPivaToBackendforConfirm(piva, docName);
  
      // If the first function succeeds, trigger the second function
      await processGoals(piva);
  
    } catch (error) {
      console.error("Error in the process chain:", error);
      alert("An error occurred while processing. Please try again.");
    }
  };


  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let timeoutId;
  
    if (isLoading) {
      setCounter(0); // Reset counter when loading starts
  
      const updateCounter = () => {
        // Increment by a random amount between 10 and 30
        const increment = Math.floor(Math.random() * 10) + 5; // Random number between 10 and 30
        setCounter((prevCounter) => prevCounter + increment);
  
        // Set a new random delay between 200ms and 800ms
        const nextDelay = Math.floor(Math.random() * 600) + 200;
  
        // Schedule the next update
        timeoutId = setTimeout(updateCounter, nextDelay);
      };
  
      // Start the first update
      updateCounter();
    } else {
      clearTimeout(timeoutId); // Clear timeout when loading stops
    }
  
    return () => clearTimeout(timeoutId); // Cleanup timeout on component unmount
  }, [isLoading]);
  
  ////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////start of JSX

  return (
    <div className="searchy-container">
      {!isAuthenticated && <UserPromptModal />}
      <div className="threadunic-container">
        <div
          style={{
            position: "relative",
            textAlign: "center",
            border: "none",
            marginRight: "30px",
            marginTop: "10px",
            color: "#092f7a"
          }}
        >
          <h1> Confermare gli Obiettivi</h1>
          <h2 style={{ fontSize: "17px", marginLeft: "5px" }}></h2>
          <div className="goals-container">
            <h3 className="goals-containerttl">Obiettivi Attuali:</h3>
            <ul className="goal-list">
              {goals.map((goal, index) => (
                <div key={index} className="goal-item-wrapper">
                  <li className="goal-list-item">
                    <div className="goal-content">
                      <p>{goal}</p>
                      <span className="tick-icon">&#10003;</span>
                    </div>
                  </li>
                  <span
                    className="cross-icon"
                    onClick={() => handleRemoveGoal(goal)} // Call handleRemoveGoal on click
                    style={{ cursor: "pointer" }} // Optional: make the icon look clickable
                  >
                    &#10006; {/* Cross icon for removing goal */}
                  </span>
                </div>
              ))}
            </ul>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "20px",
                color: "black",
              }}
            >
              {/* Toggle button to show/hide the input and add button */}
              <button
                type="button"
                onClick={() =>
                  setIsGoalInputVisible((prevVisible) => !prevVisible)
                }
                className="logout-button"
                style={{
                  marginRight: "10px",
                  fontSize: "16px",
                  backgroundColor: "#092f7a",
                  color: "#fff",
                  padding: "10px 18px",
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
              >
                {isGoalInputVisible ? "-" : "+"} Obiettivi
              </button>

              {/* Conditionally render the input and add button */}
              {isGoalInputVisible && (
                <div style={{ marginBottom: "15px", marginTop: "10px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <input
                      type="text"
                      value={goalInput}
                      onChange={(e) => setGoalInput(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleAddGoal(userId, goalInput);
                          setGoalInput("");
                          setIsGoalInputVisible(false); // Hide the input field after submission
                        }
                      }}
                      placeholder="Inserisci il tuo obiettivo"
                      style={{
                        flex: 1,
                        padding: "12px",
                        fontSize: "18px",
                        borderRadius: "4px",
                        border: "1px solid #ddd",
                        marginRight: "10px",
                      }}
                    />
                  </div>
                  <div
                    style={{
                      marginTop: "10px", // Adjust spacing
                      display: "flex",
                      justifyContent: "center",
                    }}
                    className="tick-icon-container"
                  >
                    <button
                      onClick={() => {
                        handleAddGoal(userId, goalInput);
                        setGoalInput("");
                        setIsGoalInputVisible(false); // Hide the input field after submission
                      }}
                      style={{
                        padding: "12px 18px",
                        fontSize: "18px",
                        backgroundColor: "white",
                        color: "#FF5758", // Icon color
                        border: "1px solid #ddd",
                        borderRadius: "4px",
                        cursor: "pointer",
                      }}
                      className="add-goal-button"
                    >
                      <span className="tick-icon">&#10003;</span>
                    </button>
                  </div>
                </div>
              )}

              <div
                className="explain-list-item"
                style={{
                  padding: "5px",
                  fontSize: "18px",
                  backgroundColor: "white",
                  color: "#092f7a",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  margin: "30px 0 30px 0",
                }}
              >
                Quando sei soddisfatto del tuo elenco di obiettivi, premi
                Conferma e inizieremo!
              </div>
              <button
                onClick={() => handleButtonClick(selectedPivaData.piva, selectedPivaData.docName)} 
                className="confirm-button"
                style={{
                  fontSize: "16px",
                  padding: "10px 20px",
                  backgroundColor: isLoading ? "grey" : "green",
                  color: "#fff",
                  border: "none",
                  borderRadius: "4px",
                  cursor: isLoading ? "not-allowed" : "pointer",
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  opacity: isLoading ? 0.8 : 1,
                }}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    Confermare
                    <span
                      style={{
                        position: "absolute",
                        right: "10px",
                        width: "20px",
                        height: "20px",
                        border: "3px solid rgba(255, 255, 255, 0.3)",
                        borderTop: "3px solid #fff",
                        borderRadius: "50%",
                        animation: "spin 1s linear infinite",
                      }}
                    />
                  </>
                ) : (
                  "Confermare"
                )}
              </button>
              {isLoading && (
    <div style={{
      marginTop: "20px",
      fontSize: "16px",
      color: "#333",
      textAlign: "center",
      lineHeight: "1.5",
    }}>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
      {isLoading ? (
        <div>
         
              "Perfetto! ben fatto!! Stiamo cercando nell'attuale elenco di incentivi per soddisfare i tuoi obiettivi. Una volta completato andremo subito alla pagina di ricerca dove troverai un filtro chiamato Obiettivi a te dedicato..."
           
          <div style={{ fontSize: "16px", color: "#333", marginTop: "10px" }}>
            <strong>Numero di incentivi cercati:</strong> {counter}
          </div>
        </div>
      ) : (
        <div>
          {/* Other content or message after loading completes */}
        </div>
      )}
    </div>
    </div>
  )}
            </div>
          </div>
          {showAdditionalContent && (
            <div>
              <div>
                <div>
                  <div style={{ margin: "50px" }}>
                    <Typewriter
                      text={
                        " Cosa vuoi fare per quanto rigaurda finanziamento? Descrivi la tua idea..."
                      }
                      speed={40}
                    />
                  </div>
                  {!isAuthenticated && <UserPromptModal />}
                  <div
                    style={{
                      margin: "10px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  ></div>
                </div>
                <div className="App"></div>
              </div>
              <div></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Goals;
