import "..//pages/allCSS.css";
import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { useCart } from "./cartContext.js";
import { useNavigate } from "react-router-dom";
import { loadGtag } from "..//components/gtag.js";
import ToggleSwitchPrice from "../components/toggleprice.js";

const ServiceOffersProfs = () => {
  const [showThanks, setShowThanks] = useState(false);
  const [isMonthly, setIsMonthly] = useState(true);
  const { addToCart } = useCart();
  const [serviceDetails, setServiceDetails] = useState([]);
  const navigate = useNavigate();

  const handleCheckout = () => {
    // Check if gtag is loaded and defined
    if (typeof window.gtag === "function") {
      // Send the apply event to Google Analytics
      window.gtag("event", "productSale", {
        value: 1,
      });
    } else {
      console.error("gtag is not defined or not loaded yet");
    }

    // Show the "Thanks" message instead of navigating right away
    setShowThanks(true);
  };

  const handleThanksButtonClick = () => {
    // Navigate to the home page after clicking the "Thanks" button
    navigate("/search");
  };

  const [isGtagLoaded, setIsGtagLoaded] = useState(false);

  useEffect(() => {
    // Load gtag when the component mounts and set the state when it's ready
    loadGtag()
      .then(() => {
        setIsGtagLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading gtag:", error);
      });
  }, []);




  // Handler for toggle switch changes
  const handleToggleChange = (value) => {
    setIsMonthly(value);
  };


  useEffect(() => {
    const fetchServices = async () => {
      try {
        const serviceIds = ["2Jewsb3lxiWlTMb7dQHw", "kEmew7Mg5Gx0I7f3DM0a", "cxzmelrUTmAt04kQcOUq"];
        const services = await Promise.all(
          serviceIds.map(async (id) => {
            const docRef = doc(db, "services", id);
            const docSnap = await getDoc(docRef);
            return { id, ...docSnap.data() };
          })
        );
        setServiceDetails(services);
      } catch (error) {
        console.error("Error fetching service details: ", error);
      }
    };
    fetchServices();
  }, []);

  const handleAddToCart = (service) => {
    const selectedService = {
      ...service,
      selectedPrice: isMonthly ? service.servicePrice : service.servicePriceAnnual, // Corrected
      isMonthly: isMonthly, // Indicates whether it’s monthly or annual
    };
  
    addToCart(selectedService);
    localStorage.setItem("purchasedService", JSON.stringify(selectedService)); // Store selected service in local storage
    navigate("/cart");
  };
  



  return (
    <div className="subscription-container">
      <header className="subscription-header">
        <title>Abbonamenti per ogni fase</title>
        <h1>Abbonamenti per ogni fase</h1>
        <p>
          Esplora i nostri piani tecnologici, ciascuno progettato per migliorare
          il processo di richiesta di sovvenzioni con software potenti e
          supporto esperto.
        </p>
        <div className="toggle-switch-container-price">
        <ToggleSwitchPrice
          label={isMonthly ? 'Mensile' : 'Annuale (2 mesi gratis)'}
          onChange={handleToggleChange}
          checked={isMonthly} // Pass the current state to the toggle
        />
                </div>
      </header>
      <section className="subscription-piani">

      <section className="subscription-piani">
        {serviceDetails.map((service) => (
          <div key={service.id} className="subscription-piano">
            <h2>{service.serviceName}</h2>
            <p className="subscription-prezzo">
            €{isMonthly ? service.servicePrice : service.servicePriceAnnual} + IVA
            </p>
            <button onClick={() => handleAddToCart(service)} className="subscription-button">
              Aggiungi al carrello
            </button>
            <p className="subscription-subject">{service.serviceDescription}</p>
            <ul>
            {service.serviceBenefits.map((benefit, index) => (
              <li key={index} className="subscription-tick">
                <i className="fas fa-check" aria-hidden="true"></i> {benefit}
              </li>
            ))}
          </ul>
          </div>
        ))}
      </section>
      <div className="subscription-piano subscription-completo">
  <h2>Oneshot | Addons</h2>
  <p className="subscription-subject">
    Ricarica e aggiungi altri prodotti al tuo abbonamento. <br/> I prezzi sono + IVA
  </p>

  <div className="addon-row">
    <ul>
      <li className="subscription-tick">
        <i className="fas fa-check"></i> +1 applicazione
      </li>
    </ul>
    <div className="addon-price-button">
      <p className="subscription-prezzo">€69.99</p>
      <a className="addonit-button">+</a>
    </div>
  </div>

  <div className="addon-row">
    <ul>
      <li className="subscription-tick">
        <i className="fas fa-check"></i> +10 partita iva
      </li>
    </ul>
    <div className="addon-price-button">
      <p className="subscription-prezzo">€4.99 mensile</p>
      <a  className="addonit-button">+</a>
    </div>
  </div>

  <div className="addon-row">
    <ul>
      <li className="subscription-tick">
        <i className="fas fa-check"></i> Firma Digitale
      </li>
    </ul>
    <div className="addon-price-button">
      <p className="subscription-prezzo">€39.99 annuale</p>
      <a  className="addonit-button">+</a>
    </div>
  </div>

  <div className="addon-row">
    <ul>
      <li className="subscription-tick">
        <i className="fas fa-check"></i> Newsletter dal tuo indirizzo
      </li>
    </ul>
    <div className="addon-price-button">
      <p className="subscription-prezzo">€9.99 annuale</p>
      <a  className="addonit-button">+</a>
    </div>
  </div>
</div>

      </section>
      <footer className="subscription-footer">
        <p>
          Unisciti a oltre 50+ aziende che stanno già crescendo con
          Incentivato.it
        </p>
        <a >
          Inizia subito!
        </a>
      </footer>
    </div>
  );
};

export default ServiceOffersProfs;
