import React from "react";

const Timeline = ({ openingDate, closingDate }) => {
  // Format the Firebase date strings
  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0] // Extract only the date (YYYY-MM-DD)
      : "Aperto fino a nuova communicazione"; // Default if the date is undefined
  };

  // Ensure dates are formatted correctly
  const formattedOpeningDate = formatDate(openingDate);
  const formattedClosingDate = formatDate(closingDate);

  // Helper function to calculate the difference in days
  const calculateDaysDifference = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    return Math.ceil((end - start) / (1000 * 60 * 60 * 24)); // Difference in days
  };

  const calculationClosingDate =
    formattedClosingDate === "Aperto fino a nuova communicazione"
      ? "2026-12-31" // Default calculation date
      : formattedClosingDate;

  // Helper function to calculate elapsed days
  const calculateElapsedDays = (startDate, currentDate) => {
    const start = new Date(startDate);
    const current = new Date(currentDate);
    return Math.ceil((current - start) / (1000 * 60 * 60 * 24)); // Elapsed days
  };

  // Calculate total and elapsed days
  const totalDays = calculateDaysDifference(formattedOpeningDate, calculationClosingDate); // Total duration in days
  const elapsedDays = calculateElapsedDays(
    formattedOpeningDate,
    new Date().toISOString().split("T")[0] // Get today's date in YYYY-MM-DD format
  );

  // Calculate blue dot position as a percentage
  const blueDotPosition = Math.min(Math.max((elapsedDays / totalDays) * 100, 0), 100); // Clamp between 0 and 100

  return (
    <div style={{ position: "relative", height: "60px", marginTop: "20px" , marginBottom: "20px" }}>
      {/* Timeline line */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "10%",
          width: "80%",
          height: "4px",
          background: "#d3d3d3",
          transform: "translateY(-50%)",
        }}
      />

      {/* Opening Date (Green Dot) */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "10%", // Fixed at the start of the line
          transform: "translate(-50%, -50%)",
          width: "12px",
          height: "12px",
          background: "#32CD32",
          borderRadius: "50%",
        }}
        title={`Opening Date: ${formattedOpeningDate}`}
      />

      {/* Closing Date (Red Dot) */}
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "90%", // Fixed at the end of the line
          transform: "translate(-50%, -50%)",
          width: "12px",
          height: "12px",
          background: "#FF6347",
          borderRadius: "50%",
        }}
        title={`Closing Date: ${formattedClosingDate}`}
      />

      {/* Today's Date (Blue Dot) */}
      <div
        style={{
          position: "absolute",
          top: "50%", // Ensure it's vertically centered on the line
          left: `${10 + (blueDotPosition * 0.8)}%`, // Adjust within the 10%-90% range
          transform: "translate(-50%, -50%)",
          width: "12px",
          height: "12px",
          background: "#1E90FF",
          borderRadius: "50%",
          boxShadow: "0 0 5px rgba(30, 144, 255, 0.8)",
        }}
        title={`Today: ${new Date().toLocaleDateString()} (${elapsedDays} of ${totalDays} days elapsed)`}
      />

      {/* Labels */}
      <div
        style={{
          position: "absolute",
          left: "10%",
          transform: "translateX(-50%)",
          fontSize: "12px",
        }}
      >
        {formattedOpeningDate}
      </div>
      <div
        style={{
          position: "absolute",
          left: "90%",
          transform: "translateX(-50%)",
          fontSize: "12px",
        }}
      >
        {formattedClosingDate}
      </div>
    </div>
  );
};

export default Timeline;
