///imports
import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { db, auth } from "..//..//src/config/firebase.js";
import {
  getDocs,
  getDoc,
  collection,
  addDoc,
  getFirestore,
  serverTimestamp,
  orderBy,
  where,
  query,
  doc,
  updateDoc,
  limit,
  startAfter,
  onSnapshot,
  setDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import "..//pages/allCSS.css";
import UserPromptModal from "..//..//src/components/userPrompt.js";
import App from "..//App.js";
import { useParams } from "react-router-dom";
import IncentiveSearch from "./agent2.js";
import Typewriter from "..//components/Typewriter.js";
import SSEDisplay from "..//components/SSEDisplay.js";
import renderCriteria from "..//components/criteria.js";
import RatingComponent from "..//components/RatingComponent.js";
//import LongPollDisplay from '..//components/LongPolling.js';
import DOMPurify from "dompurify";
import clientlogo from "..//../src/technosida.png";
import { useAuth } from "../../src/components/auth-email.js";
import psuimg from "../../src/postsignupimg2.png";
import "./homechat.css";

function PostSignup() {
  const [goalInput, setGoalInput] = useState(""); // State to hold input from the user
  const [goals, setGoals] = useState([]); // State to store goals fetched from Firebase
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  //console.log(threadId); // This should log the actual threadId from the URL
  const navigate = useNavigate();
  const headerStyles = {
    width: "100%",
    backgroundColor: "black",
    color: "white",
    padding: "10px",
    fontFamily: "'Poppins', sans-serif",
    textAlign: "center",
    wordSpacing: "6px",
    fontSize: "35px",
    //animation: "flash 3s infinite",
  };

  ////////////////////////////////////////////////////////////////////////////////////
  //text entry to fb db
  const [newQ1, setNewQ1] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const questionsCollectionRef = collection(db, "questions");
  const threadsCollectionRef = collection(db, "threads");
  const [lastVisible, setLastVisible] = useState(null);
  const location = useLocation();
  const { title } = location.state || { title: "Default Title" };
  const { assistant, threadId } = useParams();
  const [documents, setDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [document, setDocument] = useState(null);
  const [submissionSource, setSubmissionSource] = useState("manual");
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const [showRating, setShowRating] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [atecoL3, setAtecoL3] = useState("");
  const [companyAteco, setCompanyAteco] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [piva, setPiva] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [currentuserID, setCurrentUserID] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [comune, setComune] = useState("");
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState("");
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [shouldRerender, setShouldRerender] = useState(false);
  const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);
  const [shouldStartConversation, setShouldStartConversation] = useState(false);
  const [showAdditionalContent, setShowAdditionalContent] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isPolling, setIsPolling] = useState(false);
  const [selectedPivaData, setSelectedPivaData] = useState({
    companyName: "",
    companySize: "",
    companyTurnover: "",
    comune: "",
    companyAteco: "",
    regione: "",
    piva: "",
    currentuserID: "",
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setIsAuthenticated(!!user);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  /*get user data & ids*/
  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  async function fetchUserData() {
    if (currentUser && currentUser.email) {
      try {
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const userData = querySnapshot.docs[0].data();
          const userDoc = querySnapshot.docs[0];
          setAtecoL3(userData.companyAteco);
          setRegione(userData.regione);
          setFirstName(userData.firstName);
          setCurrentUserID(userDoc.id);
          setPiva(userDoc.piva);
          setCompanyName(userData.companyName);
          setCompanySize(userData.companySize);
          setCompanyEmployees(userData.companyEmployees);
          setCompanyTurnover(userData.companyTurnover);
          setComune(userData.comune);
          setIsUserDataLoaded(true);
          setCompanyAteco(userData.companyAteco);

          setSelectedPivaData({
            companyName: userData.companyName,
            companySize: userData.companySize,
            companyTurnover: userData.companyTurnover,
            comune: userData.comune,
            companyAteco: userData.companyAteco,
            regione: userData.regione,
            piva: userData.piva,
            currentuserID: userDoc.id,
          });

          /* console.log("Selected PIVA Data set to:", {
            companyName: userData.companyName,
            companySize: userData.companySize,
            companyTurnover: userData.companyTurnover,
            comune: userData.comune,
            companyAteco: userData.companyAteco,
            regione: userData.regione,
            piva: userData.piva,
            currentuserID: userDoc.id,
          }); */
        } else {
          console.log("No user data found for email:", currentUser.email);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    } else {
      console.log("currentUser is not defined yet");
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////
  //////////* new thread*/

  const startConversationHandler = async () => {
    if (!isUserDataLoaded) {
      console.log("User data not loaded yet.");
      return;
    }

    await startNewConversation();
  };

  const startNewConversation = async (
    assistant = "asst_9BGy863ytF0SLw8H0PyyLZrF"
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
      const userMessage = "Ciao, vorrei iniziare una nuova conversazione.";

      const requestBody = {
        message: userMessage,
        assistant: assistant,
      };

      //console.log("Request Body:", requestBody);

      const response = await fetch(`${API_BASE_URL}/api/start_new_convo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      const newThreadId = data.thread_id;

      // Store the thread ID in local storage (optional)
      localStorage.setItem("currentThreadId", newThreadId);

      // After receiving the thread ID, send user info
      /* await sendUserInfo(
        newThreadId,
        regione,
        companySize,
        companyTurnover,
        comune,
        companyEmployees,
        companyAteco,
        userId
      ); */

      // Construct the new URL with the thread ID
      const newUrl = `${location.pathname}${newThreadId}`;

      // Force page reload with the new URL
      window.location.replace(newUrl);
    } catch (error) {
      console.error("Error starting a new conversation:", error);
    }
  };

  const extractBaseCode = (ateco) => {
    // Extract only the numeric part (before any dash or text)
    const numericPart = ateco.split(" - ")[0].trim();

    // Handle cases with one decimal place (e.g., '27.9')
    if (/^\d{2}\.\d$/.test(numericPart)) {
      // Append '0' to make it '27.90'
      return numericPart + "0";
    }

    // Handle cases with two or more decimal places (e.g., '70.2209')
    if (/^\d{2}\.\d{2,}/.test(numericPart)) {
      // Truncate to two decimal places
      return numericPart.slice(0, 5);
    }

    // If no match, return the original numeric part
    return numericPart;
  };

  //const sendUserInfo = async (threadId, goals, companySize, regione, atecoL3, assistant, user_id) => {
  //// send user info
  /* const sendUserInfo = async (
    threadId,
    regione,
    companySize,
    companyTurnover,
    comune,
    companyEmployees,
    companyAteco,
    userId
  ) => {
    try {
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

      // Use extractBaseCode to process companyAteco
      const baseCode = companyAteco ? extractBaseCode(companyAteco) : "";

      // Construct the userInfo object
      const userInfo = {
        thread_id: threadId,
        regione: regione,
        companySize: companySize,
        companyTurnover: companyTurnover,
        comune: comune,
        companyEmployees: companyEmployees,
        companyAteco: companyAteco,
        codicel3: baseCode,
        user_id: userId,
      };

      //console.log("User Info:", userInfo);

      // Send the userInfo to the backend
      const response = await fetch(`${API_BASE_URL}/api/send_user_info`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userInfo),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();

      console.log("User info sent successfully:", data.message);
    } catch (error) {
      console.error("Error sending user info:", error);
    }
  }; */

  // Check if threadId exists in the URL when the component loads
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const threadIdInUrl = pathSegments.find((segment) =>
      segment.startsWith("thread_")
    );

    if (!threadIdInUrl) {
      // No thread ID found, set flag to start a new conversation after data is loaded
      setShouldStartConversation(true);
    } else {
      console.log("Thread ID already exists in URL:", threadIdInUrl);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (isUserDataLoaded && shouldStartConversation) {
      startConversationHandler();
      setShouldStartConversation(false); // Reset the flag after starting the conversation
    }
  }, [isUserDataLoaded, shouldStartConversation]);

  ///////

  useEffect(() => {
    // Call getQuestionList when the component mounts
    getQuestionList();
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  // The rest of your component...

  const getQuestionList = async () => {
    try {
      const userId = auth.currentUser ? auth.currentUser.uid : null;
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      // Create a query against the collection.
      const q = query(
        questionsCollectionRef,
        where("userId", "==", userId),
        orderBy("timestamp", "asc")
      );
      const querySnapshot = await getDocs(q);

      const filteredData = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setQuestionList(filteredData);
    } catch (err) {
      console.error("Error fetching questions:", err);
    }
  };

  const onSubmitQuestion = async () => {
    setIsPolling(true); // Set polling flag to true here
    try {
      if (!newQ1.trim()) {
        console.log("Empty question, exiting.");
        alert("Please enter a message.");
        return;
      }

      setSubmissionSource("manual");
      // Reset the input field
      setNewQ1("");

      const userId = auth.currentUser ? auth.currentUser.uid : null;
      //console.log("Current user ID:", userId);
      if (!userId) {
        console.error("User is not authenticated.");
        return;
      }

      const docRef = await addDoc(questionsCollectionRef, {
        q1: newQ1,
        userId: userId,
        timestamp: serverTimestamp(),
        threadId: threadId,
        response: "",
        source: "strategy",
      });

      //console.log("Document added with ID:", docRef.id);
      localStorage.setItem("currentDocId", docRef.id); // Store docId in local storage
      const docId = localStorage.getItem("currentDocId");

      //console.log("Attempting to add a document to threads collection...");
      const docReff = await addDoc(threadsCollectionRef, {
        userId: userId,
        threadId: threadId,
        title: title,
        assistant: assistant,
      });

      // Call the API after successfully adding the question
      await fetchCompletionGoals(
        newQ1,
        threadId,
        assistant,
        docId,
        currentuserID
      );

      setNewQ1("");

      // Optionally refresh the question list to include the latest question
      await getQuestionList();
    } catch (err) {
      console.error("Error submitting the question:", err);
    }
  };

  useEffect(() => {
    if (readyToSubmit && newQ1.trim()) {
      onSubmitQuestion();
      setReadyToSubmit(false); // Reset the trigger
    }
  }, [readyToSubmit, newQ1]);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Assume auth is your authentication instance (e.g., from Firebase)
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe(); // Cleanup subscription
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////

  const [question, setQuestion] = useState("");
  const [docId, setDocId] = useState("");
  //const [jsonData, setJsonData] = useState(Opendata); // Ensure Opendata is defined
  const [apiResponse, setApiResponse] = useState("");
  const [messages, setMessages] = useState([
    {
      role: "system",
      content:
        "You are a helpful assistant with good general knowledge for checking facts",
      file_ids: "123",
    },
  ]);

  ///////////////////////////////////////////////////////////////////////////////////////////////
  /////message back and forth logic

  //////// Send and get potential goals
  const fetchCompletionGoals = async (
    question,
    threadId,
    assistant,
    docId,
    currentuserID
  ) => {
    /* console.log(
      "Sending question to server:",
      question,
      threadId,
      assistant,
      docId,
      currentuserID
    ); */

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/fetchCompletionServerGoals`;

    try {
      // Create the request body directly within the fetch call for clarity and simplicity
      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          question: question,
          threadId: threadId,
          assistant: assistant,
          docId: docId,
          currentuserID: currentuserID,
        }),
      });

      /* console.log(
          "Request body:",
          JSON.stringify({
            question: question,
            threadId: threadId,
            assistant: assistant,
            docId: docId,
          })
        ); */

      //console.log("Response received. Status:", response.status);

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseText = await response.text(); // Fetch the raw response text
      //console.log("Raw response text:", responseText);

      try {
        const jsonResponse = JSON.parse(responseText);
        //console.log("Parsed JSON response:", jsonResponse);

        const { thread_id } = jsonResponse;
        //console.log("Received thread_id & assistant:", thread_id, assistant);

        // Optionally update the document with the new thread ID if necessary
        if (!threadId) {
          await updateDoc(doc(questionsCollectionRef, docId), {
            threadId: thread_id,
          });
          console.log("Updated document with new threadId.");
        }

        if (!assistant) {
          await updateDoc(doc(threadsCollectionRef, docId), {
            assistant: assistant,
          });
          console.log("Updated document with new assistant.");
        }

        // SSEDisplay(thread_id, docId);
        //console.log("Ready to display SSE stream.");
      } catch (parseError) {
        console.error("Error parsing JSON:", parseError);
      }
    } catch (error) {
      console.error("Error during fetch operation:", error);
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  ///Listen for Goal Completion for Navigate popup

  const [goalsCompleted, setGoalsCompleted] = useState(false);

  useEffect(() => {
    if (!currentuserID) return;

    // Reference to the user's goals document
    const goalsDocRef = doc(db, "goals", currentuserID);

    // Set up the listener
    const unsubscribe = onSnapshot(goalsDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        if (data.goals && data.goals.length > 0) {
          // Goals have been saved, update the state
          setGoalsCompleted(true);
        }
      }
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [currentuserID]);

  const handleNextPage = () => {
    if (threadId && assistant) {
      navigate(`/goals/${assistant}/${threadId}`);
    } else {
      console.error("threadId or assistant is missing");
    }
  };

  /*  const pollForResponse2 = async (
    thread_id,
    setGoalsState,
    setLoading,
    setError
  ) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/get_response2`;

    const maxAttempts = 3; // e.g., 30 attempts
    const interval = 2000; // 2 seconds
    let attempts = 0;

    const poll = async () => {
      attempts += 1;
      //console.log(`Polling attempt ${attempts} for thread_id: ${thread_id}`);

      try {
        const response = await fetch(endpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ thread_id: thread_id }),
        });

        //console.log(`Received status ${response.status} for thread_id: ${thread_id}`);

        if (response.status === 200) {
          const jsonResponse = await response.json();
          //console.log("Parsed JSON response:", jsonResponse);

          const { response2 } = jsonResponse;

          if (response2 && Array.isArray(response2)) {
            //console.log("response2 is available:", response2);
            setGoalsState(response2);
            setLoading(false);
            return;
          } else {
            //console.log("response2 is not in expected format or missing:", response2);
          }
        } else if (response.status === 202) {
          console.log(`response2 not yet available. Attempt: ${attempts}`);
        } else {
          console.warn(
            `Unexpected status code ${response.status}. Attempt: ${attempts}`
          );
        }
      } catch (error) {
        console.error(
          `Error during polling for response2 on attempt ${attempts}:`,
          error
        );
        setError("Failed to fetch goals. Please try again.");
        setLoading(false);
        return;
      }

      if (attempts < maxAttempts) {
        console.log(
          `Scheduling next polling attempt in ${interval / 1000} seconds.`
        );
        setTimeout(poll, interval);
      } else {
        console.warn("Polling for response2 timed out after maximum attempts.");
        setError("Fetching goals timed out. Please try again later.");
        setLoading(false);
        setIsPolling(false);
      }
    };

    poll();
  };

  const fetchResponse2 = async (
    thread_id,
    setGoalsState,
    setLoading,
    setError
  ) => {
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${API_BASE_URL}/api/get_response2`;

    try {
      setLoading(true);
      setError(null);

      const response = await fetch(endpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ thread_id: thread_id }),
      });

      //console.log(`Received status ${response.status} for thread_id: ${thread_id}`);

      if (response.status === 200) {
        const jsonResponse = await response.json();
        //console.log("Parsed JSON response:", jsonResponse);

        const { response2 } = jsonResponse;

        if (response2 && Array.isArray(response2)) {
          //console.log("response2 is available:", response2);
          setGoalsState(response2);
          setLoading(false);
          return;
        } else {
          console.log("response2 is not in expected format:", response2);
        }
      } else if (response.status === 202) {
        console.log("response2 not yet available.");
      } else {
        console.warn(`Unexpected status code ${response.status}.`);
      }

      // If response2 is not yet available, initiate polling
      pollForResponse2(thread_id, setGoalsState, setLoading, setError);
      setIsPolling(false);
    } catch (error) {
      console.error("Error during fetchResponse2 operation:", error);
      setError("Failed to fetch goals. Please try again.");
      setLoading(false);
    }
  }; */

  /* const GoalsBox = ({ thread_id }) => {
    const [goals, setGoals] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
      if (!isPolling || !thread_id) return; // Avoid running if polling isn't active

      const fetchAndPoll = async () => {
        await fetchResponse2(thread_id, setGoals, setLoading, setError);
      };

      fetchAndPoll(); // Only fetch and poll when isPolling is true
    }, [isPolling]); // Depend only on isPolling

    const [hasChanges, setHasChanges] = useState(false);

    const addGoal = (goal) => {
      handleAddGoal(userId, goal);
      setGoals((prevGoals) => prevGoals.filter((g) => g !== goal));
      setHasChanges(true);
    };

    return (
      <div className="goals-box">
        <h3>AI Suggested Goals:</h3>
        {loading && <p>Loading goals...</p>}
        {error && <p className="error-message">{error}</p>}
        {!loading && !error && goals.length > 0 && (
          <ul className="goal-list">
            {goals.map((goal, index) => (
              <li key={index} className="goal-list-item">
                <p>{goal}</p>
                <div className="icon-container">
                  <span
                    className="tick-icon"
                    onClick={() => addGoal(goal)} // Call addGoal on click
                    style={{ cursor: "pointer" }} // Optional: make the icon look clickable
                  >
                    +
                  </span>
                </div>
              </li>
            ))}
          </ul>
        )}
        {!loading && !error && goals.length === 0 && <p>No goals available.</p>}
      </div>
    );
  }; */

  const textAreaRef = useRef(null);

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();

    const threadIdFromStorage = localStorage.getItem("currentThreadId");
    if (!threadIdFromStorage) {
      await startNewConversation();
    } else {
      setIsPolling(true); // Enable polling after submission
      onSubmitQuestion(threadIdFromStorage);
    }
  };

  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [lastThreads, setLastThreads] = useState([]);

  const filteredQuestions = questionList.filter(
    (question) => question.threadId === threadId
  );

  const [isLoading, setIsLoading] = useState(false);

  const [threadTitle, setThreadTitle] = useState("");

  useEffect(() => {
    const fetchThreadTitle = async () => {
      if (threadId) {
        // Ensure threadId is not undefined or empty
        const threadsCollectionRef = collection(db, "threads");
        const q = query(
          threadsCollectionRef,
          where("threadId", "==", threadId)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          // Assuming each threadId value is unique and only one document should match
          const docSnap = querySnapshot.docs[0];
          setThreadTitle(docSnap.data().title); // Update state with the fetched title
        } else {
          //console.log("No such document with threadId:", threadId);
        }
      }
    };

    fetchThreadTitle();
  }, [threadId]); // Re-fetch whenever threadId changes


  const handleSiClick = () => {
    setNewQ1("Si"); // Set "Si" in the input box
    setTimeout(() => {
      onSubmitQuestion(); // Trigger submission after a tiny delay to ensure state updates
    }, 50); // Delay by 50ms to allow React to update `newQ1`
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser && currentUser.email) {
        const db = getFirestore();
        const usersCollectionRef = collection(db, "users");
        const q = query(
          usersCollectionRef,
          where("email", "==", currentUser.email)
        );

        try {
          const querySnapshot = await getDocs(q);

          if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0];
            const userData = userDoc.data();
          } else {
            console.log("No user data found for email:", currentUser.email);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    if (currentUser && currentUser.email) {
      fetchUserData();
    }
  }, [currentUser]); // This useEffect will run every time the currentUser changes

  //////////////////////////////////////////////////////////////////////////////////////////////////
  ///////FORMATTING
  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim().replace(/\r\n?/g, "\n"); // Normalize line endings
      desc = desc.replace(/【\d+:\d+†source】/g, "");
      desc = desc.replace(
        /(https?:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank" style="color: white; text-decoration: underline;">$1</a>'
      );

      let formattedText = ""; // Initialize the formatted text.
      let paragraphs = desc.split(/---\n*/); // Split by '---' for new paragraphs.

      paragraphs.forEach((paragraph, index) => {
        if (index > 0) {
          formattedText += "<p>"; // Start a new paragraph for each new section defined by '---'
        }

        // Handle bold text and convert line breaks within a paragraph into <br>
        let lines = paragraph.split("\n");
        lines = lines.map((line) => {
          if (line.startsWith("###")) {
            // Remove '###' and format the text
            const textWithoutHash = line.substring(3).trim(); // Remove the '###' and any leading/trailing whitespace
            return `<span style="font-size: 20px;">${textWithoutHash}</span>`; // Apply styling directly
          } else {
            // Replace "**text**" with "<strong>text</strong>"
            return line.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");
          }
        });

        formattedText += lines.join("<br>"); // Join lines with <br> tags

        if (index > 0) {
          formattedText += "</p>"; // Close paragraph tag only if it's not the first section
        }
      });

      // Sanitize the final HTML string to ensure it's safe to render
      const safeHtml = DOMPurify.sanitize(formattedText, {
        USE_PROFILES: { html: true },
      });

      return safeHtml; // Return the sanitized HTML
    }
    return "No description available"; // Fallback if input is not a string
  };

  const formatDate = (dateString) => {
    return dateString ? dateString.split("T")[0] : "N/A"; // Default to 'N/A' if dateString is undefined
  };

  const toggleAdditionalContent = () => {
    setShowAdditionalContent(!showAdditionalContent);
  };
  ////////////////////////////////////////////////////////////////////////////////////////

  const [criteriaStates, setCriteriaStates] = useState({});
  //const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if (document) {
      const initialStates = {};
      for (let i = 1; i <= 15; i++) {
        const criteriaKey = `criteria_${i}`;
        if (
          document[criteriaKey] &&
          document[criteriaKey] !== "Not specified"
        ) {
          initialStates[criteriaKey] = "Non"; // Default to "Non" instead of leaving it undefined
        }
      }
      setCriteriaStates(initialStates);
    }
  }, [document]);

  /*  useEffect(() => {
      //console.log("Checking all criteria states:", criteriaStates);
      const allSelectedSi = Object.values(criteriaStates).every(
        (state) => state === "Si"
      );
      //console.log("Are all selected 'Si'? :", allSelectedSi);
      setShowPopup(allSelectedSi);
    }, [criteriaStates]); */

  const handleButtonClick = (
    buttonType,
    criterionKey,
    criterionDescription
  ) => {
    if (buttonType === "?") {
      const fullText = `cosa significa esattamente questo criterio: ${criterionDescription}`;
      setNewQ1(fullText);
      setSubmissionSource("questionButton");

      if (textAreaRef.current) {
        textAreaRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }

      // Directly call handleSubmit here without creating a new Event
      handleSubmit(); // Just call the function
    } else {
      setCriteriaStates((prevStates) => ({
        ...prevStates,
        [criterionKey]: buttonType,
      }));
    }
  };

  const sendPivaToBackendforConfirm = async (piva, docId) => {
    console.log("Sending piva to backend:", piva);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    setIsLoading(true); // Start loading

    try {
      const payload = { piva, docId };
      const response = await fetch(`${API_BASE_URL}/api/pivaconfirm`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        // Attempt to parse error message from response
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Backend process triggered successfully:", responseData);
      alert(
        "l'agente ha mappato con successo i tuoi nuovi obiettivi per gli incentivi!"
      );
    } catch (error) {
      console.error("Error triggering backend process:", error);
      alert(`Error: ${error.message}`);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  

  const renderCriteria = () => {
    let criteriaComponents = [];
    for (let i = 1; i <= 15; i++) {
      const criteriaKey = `criteria_${i}`;
      if (document[criteriaKey] && document[criteriaKey] !== "Not specified") {
        criteriaComponents.push(
          <Criterion
            key={criteriaKey}
            criterionName={criteriaKey}
            description={document[criteriaKey]}
            selected={criteriaStates[criteriaKey]}
            onButtonClick={(buttonType) =>
              handleButtonClick(buttonType, criteriaKey, document[criteriaKey])
            }
          />
        );
      }
    }
    return criteriaComponents;
  };

  const Criterion = ({
    criterionName,
    description,
    selected,
    onButtonClick,
  }) => {
    const buttonStyle = (buttonType) => ({
      backgroundColor:
        buttonType === selected
          ? buttonType === "Si"
            ? "green"
            : buttonType === "?"
            ? "orange"
            : "red"
          : "",
      color: buttonType === selected ? "white" : "black",
      margin: "0 4px",
      cursor: "pointer",
    });

    return (
      <div className="criteria">
        <p>{description}</p>
        <div>
          <button style={buttonStyle("Si")} onClick={() => onButtonClick("Si")}>
            Si
          </button>
          <button
            style={buttonStyle("Non")}
            onClick={() => onButtonClick("Non")}
          >
            Non
          </button>
          <button style={buttonStyle("?")} onClick={() => onButtonClick("?")}>
            Chiede all'agente
          </button>
        </div>
      </div>
    );
  };

  const [fullResponse, setFullResponse] = useState(null);

  const [doceId, setDoceId] = useState(localStorage.getItem("currentDocId")); // Initially fetch from local storage

  // Polling local storage for changes in docId
  useEffect(() => {
    const interval = setInterval(() => {
      const currentDocId = localStorage.getItem("currentDocId");
      if (doceId !== currentDocId) {
        setDoceId(currentDocId); // Update state if there's a change
      }
    }, 1000); // checks every second

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [doceId]);

  // Firestore document listener effect
  useEffect(() => {
    if (doceId) {
      const docRef = doc(db, "questions", doceId);

      const unsubscribe = onSnapshot(
        docRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data.response) {
              // Check if response field is populated
              //console.log("Response updated:", data.response);
              setFullResponse(data.response); // Store the full response in state
            } else {
              //console.log("Response field is not yet populated.");
              setFullResponse(null); // Reset if response is not ready
            }
          } else {
            console.log("No such document!");
          }
        },
        (error) => {
          console.error("Error listening to the document:", error);
        }
      );

      return () => unsubscribe(); // Clean up the listener when the component unmounts or doceId changes
    }
  }, [doceId]);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  ///////////start of JSX

  return (
    <div className="searchy-container">
      {!isAuthenticated && <UserPromptModal />}
      <div className="threadunic-container">
        <div
          style={{
            position: "relative",
            textAlign: "left",
            border: "none",
            marginRight: "30px",
            marginTop: "10px",
          }}
        >
          <h1
            style={{
              textAlign: "center",
              paddingTop: "20px",
              paddingBottom: "20px",
              lineHeight: "3.5rem",
              color: "#092f7a"
            }}
          >
            {" "}
            🎉 Benvenuto su Incentivato!
          </h1>
          <div className="image-psu" style={{ cursor: "pointer" }}>
            <img src={psuimg} alt=" " />
          </div>
          <h2
            style={{
              fontSize: "25px",
              paddingTop: "25px",
              lineHeight: "2.5rem",
              color: "#092f7a"
            }}
          >
            Ciao e benvenuto! 
            <br />
            <br />
            Siamo entusiasti di averti con noi e di
            supportarti nelle tue opzioni di finanziamento.
            Prima di iniziare a cercare gli incentivi su misura per te,
            conosciamoci un po' meglio.
            Iniziamo questo adventure insieme!!! 
            <br />
            <br />
            Sei pronto? 🚀
          </h2>
          {hasChanges && (
            <button
              onClick={() =>
                sendPivaToBackendforConfirm(
                  selectedPivaData.piva,
                  selectedPivaData.docName
                )
              }
              className="logout-button"
              style={{
                marginRight: "10px",
                fontSize: "16px",
                position: "relative",
                paddingRight: isLoading ? "40px" : "10px", // Adjust padding when loading
                cursor: isLoading ? "not-allowed" : "pointer",
                opacity: isLoading ? 0.9 : 1,
                background: isLoading ? "grey" : "#FF5758",
              }}
              disabled={isLoading} // Disable button while loading
            >
              {isLoading ? (
                <>
                  Confermare
                  <span
                    className="spinner"
                    style={{
                      position: "absolute",
                      right: "10px",
                      width: "20px",
                      height: "20px",
                      border: "3px solid rgba(255, 255, 255, 0.3)",
                      borderTop: "3px solid #fff",
                      borderRadius: "50%",
                      animation: "spin 1s linear infinite",
                    }}
                  ></span>
                </>
              ) : (
                "Confermare"
              )}
            </button>
          )}

          <div>
            <div>
              <div>
                <div style={{ margin: "50px" }}></div>
                {!isAuthenticated && <UserPromptModal />}
                <div
                  style={{
                    margin: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                ></div>
              </div>
              <div className="App">
                {/*   <SimpleQuestionDisplay threadId={threadId} /> */}
              </div>
              <div className="full-display">
                <div className="questions-display">
                  {filteredQuestions.map((question, index) => {
                    // Convert timestamp to JavaScript Date object
                    const questionDate = question.timestamp?.toDate();

                    // Format the date as a simple string for comparison (ignoring time part)
                    const questionDateString =
                      questionDate?.toLocaleDateString();

                    // Check if this is the first question of a new day
                    const isFirstQuestionOfDay =
                      index === 0 ||
                      filteredQuestions[index - 1]?.timestamp
                        ?.toDate()
                        ?.toLocaleDateString() !== questionDateString;

                    const isLastQuestion =
                      index === filteredQuestions.length - 1;

                    return (
                      <div key={question.id} className="question-item">
                        {/* Display the timestamp if it's the first question of the day */}
                        {isFirstQuestionOfDay && (
                          <div className="date-display">
                            {/*   <small>{questionDateString}</small> */}
                          </div>
                        )}
                        <div>
                          <div
                            style={{
                              lineHeight: "1.6",
                              background: "#7e8ee6",
                              padding: "8px",
                              borderRadius: "5px",
                              fontSize: "22px"
                            }}
                          >
                            {/*  <strong>User:</strong>  */}
                            {question.q1}
                          </div>
                        </div>
                        <div
                          className="hc-input4"
                          style={{
                            lineHeight: "1.6",
                            marginTop: "20px",
                            background: "#394896",
                            padding: "8px",
                            borderRadius: "5px",
                          }}
                        >
                          {/* <strong>Agent:</strong>{" "} */}
                          {isLastQuestion &&
                            (!fullResponse ? (
                              <SSEDisplay
                                threadId={question.threadId}
                                docId={question.id}
                                setApiResponse={setApiResponse}
                                setIsLoading={setIsLoading}
                              />
                            ) : (
                              // Using dangerouslySetInnerHTML to inject the HTML
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: formatDescription(fullResponse),
                                }}
                              />
                            ))}
                          {!isLastQuestion && question.response && (
                            <div>
                              {question.response
                                .split(". ")
                                .map((sentence, idx, arr) => (
                                  <p key={idx}>
                                    {sentence +
                                      (idx < arr.length - 1 ? "." : "")}
                                  </p>
                                ))}
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div>
              <div className="threadunic-container-landing-lb">
                <div className="className=hc-input2">
                  <form onSubmit={handleSubmit} className="hc-input1">
                    <textarea
                      ref={textAreaRef}
                      placeholder="Message"
                      onChange={(e) => setNewQ1(e.target.value)}
                      value={newQ1}
                      className="hc-input5"
                      maxLength="1000"
                      required
                      style={{
                        height: "auto",           // Start with auto height
                        minHeight: "46px",         // Set a minimum height
                        width: "410px",
                        overflow: "hidden",        // Hide scrollbar to improve UX
                      }}
                      rows="1"
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          handleSubmit(e);
                        }
                      }}
                      onInput={(e) => {
                        if (textAreaRef.current) {
                          textAreaRef.current.style.height = "auto";
                          textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
                        }
                      }}
                    />

                    <button type="submit" className="hc-input3">
                      INVIA
                    </button>
                  </form>
                </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "30px",
                    height: "50px"
                  }}
                >
                  {goalsCompleted && (
                 <button className="move-button" onClick={handleNextPage}>
                 Procedi
               </button>
                  )}
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PostSignup;
