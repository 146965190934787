import React, { useState, useEffect } from 'react';
import { useCart } from './cartContext.js';
import { useNavigate } from "react-router-dom";

export default function Cart() {
  const navigate = useNavigate();
  const { cartItems, removeFromCart } = useCart();
  const handleCheckout = () => navigate('/checkout');
  
  return (
    <div style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "40px",
    }}>
      <h1 style={{ textAlign: "center", marginBottom: "20px" }}>Your Cart</h1>
      
      <div style={{
        width: "80%",
        maxWidth: "600px",
        padding: "20px",
        border: "1px solid #ddd",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
      }}>
        {cartItems.length === 0 ? (
          <p style={{ textAlign: "center" }}>Your cart is empty.</p>
        ) : (
          <ul style={{ listStyleType: "none", padding: "0" }}>
            {cartItems.map((item, index) => (
              <li key={index} style={{
                marginBottom: "20px",
                padding: "15px",
                borderRadius: "8px",
                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                backgroundColor: "#fff",
              }}>
                <div style={{ fontWeight: "bold", marginBottom: "8px" }}>{item.serviceName}</div>
                <div>Service Provider: {item.serviceType}</div>
                <div style={{ marginBottom: "8px" }}>{item.serviceDescription}</div>
                <div>Service Benefits:
                  <ul style={{ paddingLeft: "20px", margin: "8px 0" }}>
                    {item.serviceBenefits.map((benefit, idx) => (
                      <li key={idx} style={{ marginBottom: "4px" }}>
                        <i className="fas fa-check" style={{ color: "green", marginRight: "8px" }} aria-hidden="true"></i>
                        {benefit}
                      </li>
                    ))}
                  </ul>
                </div>
                <div style={{ fontWeight: "bold", marginBottom: "8px" }}>
                  Price: €{typeof item.selectedPrice === 'number' ? item.selectedPrice.toFixed(2) : "N/A"}
                </div>
                <button
                  style={{
                    padding: "10px 20px",
                    backgroundColor: "#f44336",
                    color: "#fff",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer",
                    marginTop: "10px",
                  }}
                  onClick={() => removeFromCart(item.id)}
                >
                  Remove
                </button>
              </li>
            ))}
          </ul>
        )}
        <button
          onClick={handleCheckout}
          style={{
            display: "block",
            width: "100%",
            padding: "15px",
            marginTop: "20px",
            backgroundColor: "#4CAF50",
            color: "white",
            fontSize: "16px",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Go to Checkout
        </button>
      </div>
    </div>
  );
}
