import React, { useState } from 'react';

const ToggleSwitchPrice = ({ label, onChange }) => {
    const [state, setState] = useState(true); // true for "Monthly", null for "Annually"

  // Function to handle toggle
  const toggleSwitch = () => {
    const newState = state === true ? null : true;
    setState(newState);
    // Emit "Y" for true and "" for null
    onChange(newState === true ? "Y" : "");
  };

    return (
        <div style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "10px",
            alignItems: "center"
        }}>
            <label style={{
                marginRight: "10px",
                minWidth: "150px"
            }}>{label}:</label>
            <div onClick={toggleSwitch} style={{
                width: "50px",
                height: "24px",
                backgroundColor: state === null ? "#4CAF50" : state ? "#ccc" : "#ff6347", // Grey for All, Green for Y, Red for N
                borderRadius: "12px",
                position: "relative",
                cursor: "pointer",
                transition: "background-color 0.3s"
            }}>
                <div style={{
                    position: "absolute",
                    top: "2px",
                    left: state === null ? "0" : state ? "26px" : "2px", // Center for All, Right for Y, Left for N
                    width: "20px",
                    height: "20px",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    transition: "left 0.3s"
                }} />
            </div>
        </div>
    );
};

export default ToggleSwitchPrice;
