import React, { useState, useEffect, useRef } from "react";
import { auth, db } from "../config/firebase.js";
import {
  getFirestore,
  query,
  where,
  doc,
  collection,
  getDocs,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../../src/components/auth-email.js";
import ToggleSwitchSearch from "../components/togglesearch.js";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";
import "..//pages/allCSS.css";
import { Spinner } from "react-bootstrap";
import { FaUser, FaTimes, FaFilter } from "react-icons/fa";
import Timeline from "..//../src/components/timeline.js";

function IncentiveSearchFree() {
  const [atecoL3, setAtecoL3] = useState("");
  const [regione, setRegione] = useState("");
  const [firstName, setFirstName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [companyEmployees, setCompanyEmployees] = useState("");
  const [companyServices, setCompanyServices] = useState("");
  const [companyTurnover, setCompanyTurnover] = useState(0);
  const [Descrizione, setDescrizione] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [incentives, setIncentives] = useState("");
  const [documents, setDocuments] = useState([]);
  const [incentivesCount, setIncentivesCount] = useState(0);
  const [totalIncentives, setTotalIncentives] = useState(0);
  const [totalFunding, setTotalFunding] = useState(0);
  const [timeSeriesData, setTimeSeriesData] = useState([]);
  const [incentivesData, setIncentivesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoadingIncentives, setIsLoadingIncentives] = useState(true);
  const [isLoadingFunding, setIsLoadingFunding] = useState(true);
  const [isLoadingChart, setIsLoadingChart] = useState(true);
  const [cw, setCw] = useState("");
  const [lastDataUpdate, setLastDataUpdate] = useState(Date.now());
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [shouldRerender, setShouldRerender] = useState(false);
  const [showFiltersGroups, setShowFiltersGroups] = useState(false);
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [isAscending, setIsAscending] = useState({
    openingDate: false,
    closingDate: false,
  });

  //////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////
  ////

  const handleSortToggle = (field) => {
    const newOrder = !isAscending[field];
    setIsAscending((prevState) => ({
      ...prevState,
      [field]: newOrder,
    }));
    setSortBy(`${field}${newOrder ? "Asc" : "Desc"}`);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////
  // FETCH DATA AND APPLY FILTERS

  const [filters, setFilters] = useState({
    CW: null,
    level: "",
    searchKeyword: "",
  });

  const [filteredCount, setFilteredCount] = useState(0);

  // Function to handle filter changes
  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  let displayedIncentives = incentives;

  if (filters.level) {
    displayedIncentives = displayedIncentives.filter(
      (doc) => doc.Level === filters.level
    );
  }

  // If you had a numeric CW filter:
  if (filters.CW != null) {
    displayedIncentives = displayedIncentives.filter(
      (doc) => doc.CW === filters.CW
    );
  }

  if (filters.searchKeyword && filters.searchKeyword.trim() !== "") {
    const keyword = filters.searchKeyword.toLowerCase();
    displayedIncentives = displayedIncentives.filter((incentive) => {
      const titolo = incentive.Titolo || "";
      return titolo.toLowerCase().includes(keyword);
    });
  }

  // Updated fetchIncentivesData function
  const fetchIncentivesData = async () => {
    try {
      //console.log("Fetching all incentives...");
      const incentivesCollectionRef = collection(db, "incentivi");
      const querySnapshot = await getDocs(incentivesCollectionRef);

      let incentivesData = [];
      querySnapshot.forEach((doc) => {
        const incentiveData = doc.data();
        incentivesData.push({
          ...sanitizeIncentiveData(incentiveData),
          ID_Incentivo: doc.id,
        });
      });

      //console.log("Fetched incentives:", incentivesData);
      setIncentives(incentivesData);
      setFilteredCount(incentivesData.length);
    } catch (error) {
      console.error("Error fetching incentives:", error);
      setIncentives([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch all incentives once on component mount
    fetchIncentivesData();
  }, []);

  // Helper function to sanitize NaN values in an incentive
  const sanitizeIncentiveData = (incentive) => {
    const sanitizedIncentive = { ...incentive };
    for (const key in sanitizedIncentive) {
      if (
        typeof sanitizedIncentive[key] === "number" &&
        isNaN(sanitizedIncentive[key])
      ) {
        sanitizedIncentive[key] = "N"; // Replace NaN with "N" or other default value
      } else if (sanitizedIncentive[key] == null) {
        sanitizedIncentive[key] = "N"; // Replace null or undefined with "N"
      }
    }
    return sanitizedIncentive;
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////////////////////////////////////
  ////////// FORMATING

  const formatDescription = (desc) => {
    if (typeof desc === "string") {
      desc = desc.trim(); // Trim whitespace at the start and end

      // Remove everything before and including the first colon on the first line
      desc = desc.replace(/^[^:]*:\s*/, "");

      // Normalize new lines and handle the initial bullet point
      let lines = desc.split(/\n+/); // Split the description into lines
      lines = lines.reduce((filteredLines, line, index) => {
        line = line.trim(); // Trim each line to remove leading/trailing whitespace

        // Skip empty lines to avoid adding extra spaces
        if (line === "") {
          return filteredLines;
        }

        // Handle numbered lines to not start with a bullet
        if (/^\d+\./.test(line)) {
          filteredLines.push(line); // Return the line as is if it starts with a number and a period
        } else if (index > 0 && filteredLines.length > 0) {
          // Apply bullets to lines that are not the first line and not after an empty line
          filteredLines.push("• " + line);
        } else {
          filteredLines.push(line); // First line or any line not fitting the above criteria gets no bullet
        }

        return filteredLines;
      }, []);

      // Join the lines back together, inserting a newline between each
      return lines.join("\n");
    }
    return "No description available"; // Return a default message if no description is provided
  };

  const getCategoryClass = (category) => {
    const categoryClasses = {
      "Crisi d'impresa": "crisi-impresa",
      Digitalizzazione: "digitalizzazione",
      "Imprenditoria femminile": "imprenditoria-femminile",
      "Innovazione e ricerca": "Innovazione-ricerca",
      "Sostegno investimenti": "Sostegno-investimenti",
      "Sostegno liquidità": "Sostegno-liquid",
      "Start up/Sviluppo d'impresa": "Startup-impresa",
      "Crisi d'impresa": "Crisi-impresa",
      "Inclusione sociale": "Inclusione-sociale",
      "Imprenditoria giovanile": "Imprenditoria-giovanile",
      Internazionalizzazione: "Internazionalizzazione",
      "Transizione ecologica": "Transizione-ecologica",
      // Add more mappings here...
    };
    return categoryClasses[category] || "default-category"; // Fallback class
  };

  const formatDate = (dateString) => {
    return dateString
      ? dateString.split("T")[0]
      : "Aperto fino a nuova communicazione"; // Default to 'N/A' if dateString is undefined
  };

  const [description, setDescription] = useState("");

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  //////////////////////////////////////////////////////////////////////////////
  /////////////////
  /// Nav
  const navigate = useNavigate();

  const extractBase = (ateco) => {
    // Regex to capture two digits before and one or two digits after the decimal point
    const numericalPartMatch = ateco.match(/^(\d{2}\.\d{1,2})/);
    let numericalPart = "";

    if (numericalPartMatch) {
      numericalPart = numericalPartMatch[1];
      const decimalParts = numericalPart.split(".");

      // Ensure two decimal places by padding with a zero if necessary
      if (decimalParts[1].length === 1) {
        numericalPart = `${decimalParts[0]}.${decimalParts[1]}0`;
      }
    }

    return numericalPart;
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const handleItemClick = (titolo) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      searchKeyword: titolo, // Should match the clicked item's Titolo
    }));
    setIsDropdownVisible(false);
  };

  const searchContainerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsDropdownVisible(false); // Hide the dropdown if clicked outside
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const [levels, setLevels] = useState([
    "Nazionale",
    "Regionale",
    "Provincia",
    "Locale",
    "Altro",
  ]);

  const getLevelColor = (level) => {
    switch (level) {
      case "Nazionale":
        return "#092f7a"; // Red
      case "Regionale":
        return "#d44747"; // Green
      case "Provincia":
        return "#ff8500"; // Blue
      case "Locale":
        return "#FF33A6"; // Pink
      case "Altro":
        return "#AAAAAA"; // Gray
      default:
        return "#000000"; // Black
    }
  };

  const [showDescription, setShowDescription] = useState(false);

  const calculateDaysLeft = (date) => {
    const today = new Date();
    const targetDate = new Date(date);
    const difference = Math.ceil((targetDate - today) / (1000 * 60 * 60 * 24));
    return difference > 0 ? ` tra ${difference} giorni` : "-";
  };

  document.querySelectorAll(".incentivy-container-comm3 ul").forEach((ul) => {
    const items = ul.querySelectorAll("li");
    if (items.length === 1) {
      ul.classList.add("single-item");
      ul.classList.remove("double-item");
    } else if (items.length === 2) {
      ul.classList.add("double-item");
      ul.classList.remove("single-item");
    } else {
      ul.classList.remove("single-item", "double-item");
    }
  });

  const sortedIncentives = [...displayedIncentives].sort((a, b) => {
    if (sortBy === "openingDateAsc") {
      return new Date(a.Data_apertura) - new Date(b.Data_apertura);
    } else if (sortBy === "openingDateDesc") {
      return new Date(b.Data_apertura) - new Date(a.Data_apertura);
    } else if (sortBy === "closingDateAsc") {
      return new Date(a.Data_chiusura) - new Date(b.Data_chiusura);
    } else if (sortBy === "closingDateDesc") {
      return new Date(b.Data_chiusura) - new Date(a.Data_chiusura);
    }
    return 0; // No sorting if sortBy is not set
  });

  // If we’re not showing all, only show the first 9
  const [visibleCount, setVisibleCount] = useState(9);
  const visibleIncentives = sortedIncentives.slice(0, visibleCount);

  const handleReadMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  useEffect(() => {
    setFilteredCount(displayedIncentives.length);
  }, [displayedIncentives]);

  /////////////////////////////////////////////////////////////////////////////////////////////////

  return (
    <div className="searchy-container">
      <div className="searchy-containered">
        <div>
          <div className="agent2-containers">
            <div
className="searchfreecontainer"
            >
              {/* Filtered Count */}
              <h1 style={{ margin: "0", color: "#092f7a", fontWeight: "900", minWidth: "300px"}}>
                <span style={{ color: "#FF5758"}}>{filteredCount}</span>{" "}
                Incentivi
              </h1>

              {/* Search Bar */}
              <div
                className="search-container-free"
                style={{
                  flex: 1, // Takes up remaining space
                  display: "flex", // Flex for alignment
                }}
                ref={searchContainerRef}
              >
                <div
                  className="search-container"
                  style={{
                    position: "relative", // Needed for absolute positioning of the icon
                    width: "100%", // Full width
                    maxWidth: "400px", // Limit max width
                  }}
                >
                  {/* Search Icon */}
                  <span
                    style={{
                      position: "absolute",
                      left: "20px", // Adjust the icon's position inside the input
                      top: "50%",
                      transform: "translateY(-50%)", // Vertically center the icon
                      fontSize: "18px",
                      color: "#ccc", // Icon color
                      pointerEvents: "none", // Make the icon unclickable
                    }}
                  >
                    🔍
                  </span>

                  {/* Input Field */}
                  <input
                    type="text"
                    placeholder="Ricerca per titolo"
                    value={filters.searchKeyword}
                    onChange={(e) =>
                      handleFilterChange("searchKeyword", e.target.value)
                    }
                    onClick={() => setIsDropdownVisible(true)}
                    style={{
                      width: "100%", // Full width for the search bar
                      padding: "10px 10px 10px 35px", // Add left padding to make space for the icon
                      borderRadius: "8px", // Rounded corners
                      border: "1px solid #ccc", // Border style
                      fontSize: "16px", // Font size
                    }}
                  />
                </div>

                {isDropdownVisible &&
                  filters.searchKeyword &&
                  incentives.length > 0 && (
                    <ul
                      className="search-results"
                      style={{
                        position: "absolute", // Position dropdown relative to parent
                        top: "calc(100% + 5px)", // Below the input
                        left: 0,
                        zIndex: 10, // Ensure it appears above other elements
                        background: "#fff",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        listStyleType: "none",
                        margin: 0,
                        padding: "10px",
                        maxHeight: "200px", // Limit height for scroll
                        overflowY: "auto",
                        width: "100%", // Match input width
                      }}
                    >
                      {incentives
                        .filter((incentive) =>
                          incentive.Titolo.toLowerCase().includes(
                            filters.searchKeyword.toLowerCase()
                          )
                        )
                        .slice(0, 5)
                        .map((incentive) => (
                          <li
                            className="search-result-item"
                            key={incentive.ID_Incentivo}
                            onClick={() => handleItemClick(incentive.Titolo)}
                            style={{
                              padding: "8px",
                              cursor: "pointer",
                              borderBottom: "1px solid #eee",
                            }}
                          >
                            {incentive.Titolo}
                          </li>
                        ))}
                    </ul>
                  )}
              </div>
            </div>
          </div>

          <div
            style={{
              margin: "7px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                textAlign: "left",
                fontSize: "16px",
              }}
            >
              <div>
                <div style={{ textAlign: "left", fontSize: "16px" }}>
                  <div>
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                    {/* {responseMessage && formatResponseMessage(responseMessage)} */}
                  </div>
                </div>
                <div>
                  <div
                    className="incentivy-container-comm3"
                    style={{ background: "#092f7a" }}
                  >
                    {errorMessage && (
                      <p style={{ color: "red" }}>{errorMessage}</p>
                    )}
                   {!isFilterEnabled && (
                    <button
                      className="filter-services-box"
                      onClick={() => navigate("/service-offers")}
                    >
                      UPGRADE per ricevere un servizio iper-personalizzato per comune, regione e ATECO.
                      <br />
                      Aggiorna i tuoi obiettivi e ricevi notizie dedicate!
                    </button>
                  )}

                    <div className="filterfreebox">
                      <div className="filterfreebox-display">
                      <div>
                        <select
                          id="level-dropdown"
                          className="level-dropdownLV"
                          value={filters.level}
                          onChange={(e) => {
                            const selectedValue = e.target.value;
                            handleFilterChange("level", selectedValue); // This triggers fetchIncentivesData with new filters
                          }}
                        >
                          <option value="">Livello</option>
                          {levels.map((level) => (
                            <option key={level} value={level}>
                              {level}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div style={{ color: "white" }}>
                      <ToggleSwitchSearch
  label={
    <span style={{ fontSize: "14px", lineHeight: "1.2", textAlign: "center" }}>
      Settimana
      <br />
      Corrente
    </span>
  }
  onChange={(value) => handleFilterChange("CW", value ? 1 : null)}
/>

                      </div>
                      </div>
                      <div>
                      <button
                        onClick={() => handleSortToggle("openingDate")}
                        style={{
                          border: "1px solid white",
                          backgroundColor: "transparent",
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "12px",
                        }}
                      >
                        Data Apertura {isAscending.openingDate ? "▲" : "▼"}
                      </button>
                      <button
                        onClick={() => handleSortToggle("closingDate")}
                        style={{
                          border: "1px solid white",
                          backgroundColor: "transparent",
                          color: "white",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          cursor: "pointer",
                          fontSize: "12px",
                          marginLeft: "10px"
                        }}
                      >
                        Data Chiusura {isAscending.closingDate ? "▲" : "▼"}
                      </button>
                    </div>
                    </div>
                    {loading ? (
                      <Spinner
                        animation="border"
                        role="status"
                        style={{
                          display: "block",
                          margin: "auto",
                          color: "white",
                        }}
                      >
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : incentives.length > 0 ? (
                      <ul style={{ padding: "10px", listStyleType: "none" }}>
                        {visibleIncentives.map((doc, index) => (
                          <li
                            key={index}
                            style={{
                              marginBottom: "20px",
                              border: "1px solid #ccc",
                              padding: "8px",
                              borderRadius: "8px",
                              boxShadow:
                                "0 4px 6px rgba(0,0,0,0.1), 5px 5px 5px #FF5758",
                              backgroundColor: "#fff", // adds a clean white background
                              lineHeight: "2rem",
                              fontSize: "20px",
                              position: "relative", // Needed for absolute positioning of the arrow
                            }}
                          >
                            <div
                              style={{
                                position: "absolute",
                                top: "2px",
                                right: "10px",
                                display: "flex",
                                alignItems: "center",
                                margin: "10px 0 10px 0",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingLeft: "20px",
                                  paddingRight: "20px",
                                }}
                              ></div>
                            </div>
                            <div
                              className="topyadjf"
                              style={{
                                backgroundColor: getLevelColor(doc.Level),
                                color: "#fff", // Optional: Adjust text color for readability
                                padding: "10px",
                                borderRadius: "5px",
                              }}
                            >
                              {formatDate(doc.Level)}
                            </div>
                            <img
                              className="image-article-searchid"
                              src={doc.urlImage}
                              alt={`Image of ${doc.Titolo}`}
                            />
                            <h2 style={{ marginTop: "20px", color: "black" }}>
                              {doc.Titolo}
                            </h2>
                            <strong
                              style={{ marginTop: "20px", color: "black" }}
                            >
                              {doc.KeyBenefit} <br />
                            </strong>
                            <p style={{marginTop: "10px"}}>
                              <a
                                href={doc.Link_istituzionale}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                info
                              </a>
                            </p>
                            <p style={{ color: "black", fontSize:"13px", marginBottom:"1px" }}>
                              Chiusura:{" "}
                              {calculateDaysLeft(doc.Data_chiusura)}
                            </p>
                            <Timeline
                              openingDate={doc.Data_apertura}
                              closingDate={doc.Data_chiusura}
                            />
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Loading, Aspeta...</p>
                    )}
                    {visibleCount < sortedIncentives.length && (
                    <button
                      style={{
                        display: "block",
                        margin: "20px auto",
                        padding: "10px 15px",
                        backgroundColor: "#092f7a",
                        color: "#fff",
                        borderRadius: "5px",
                        cursor: "pointer",
                        fontSize: "16px",
                        border: "none",
                      }}
                      onClick={handleReadMore}
                    >
                      Read More{" "}
                      <span style={{ fontSize: "20px", marginLeft: "5px" }}>
                        ↓
                      </span>
                    </button>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IncentiveSearchFree;
