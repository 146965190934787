export function loadGtag() {
  return new Promise((resolve, reject) => {
    // Check if gtag is already defined to avoid loading it multiple times
    if (typeof window.gtag === 'function') {
      resolve(); // gtag is already loaded and ready to use
    } else {
      // Dynamically load the gtag.js script
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-2PCKTPQ4E1';
      document.head.appendChild(script);

      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        window.gtag = gtag;

        gtag('js', new Date());
        gtag('config', 'G-2PCKTPQ4E1');

        console.log("gtag.js loaded and initialized.");
        resolve(); // Resolve the promise after gtag is initialized
      };

      script.onerror = () => {
        console.error("Failed to load gtag.js");
        reject("Failed to load gtag.js");
      };
    }
  });
}
