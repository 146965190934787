import React from 'react';
import '../../src/pages/allCSS.css';

const StageIndicator = ({ currentStage }) => {
    const stages = ['Ricerca', 'Criteri', 'Prerequisiti', 'Caricamento', 'Domanda', 'Rendicontazione'];

    return (
        <div className="stage-indicator">
            {stages.map((stage, index) => (
                <div className="stage-container" key={index}>
                    <div
                        className={`circle ${
                            currentStage > index + 1
                                ? 'completed'
                                : currentStage === index + 1
                                ? 'active'
                                : 'pending'
                        }`}
                    >
                        <div
                            className={`tick ${
                                currentStage > index + 1
                                    ? 'tick-completed'
                                    : 'tick-pending'
                            }`}
                        >
                            ✓
                        </div>
                    </div>
                    <div className="label" style={{color: "black", whiteSpace: "nowrap"}}>{stage}</div>
                </div>
            ))}
        </div>
    );
};

const SidebarLayout = ({ currentStage, children }) => {
    return (
        <div className="sidebar-layout">
            <div className="sidebar">
                <StageIndicator currentStage={currentStage} />
            </div>
            <div className="content">
                {children}
            </div>
        </div>
    );
};

export default SidebarLayout;
