import "..//pages/allCSS.css";
import React, { useState, useEffect } from "react";
import { db, auth } from "../config/firebase.js";
import { doc, getDoc } from "firebase/firestore";
import { useCart } from "./cartContext.js";
import { useNavigate } from "react-router-dom";
import { loadGtag } from "..//components/gtag.js";
import ToggleSwitchPrice from "../components/toggleprice.js";
import "./about.css";
import prod1 from "../prod1.png";
import prod2 from "../prod2.png";
import prod3 from "../prod3.png";
import prod4 from "../prod4.png";
import prod5 from "../prod5.png";
import prod6 from "../prod6.png";

const AboutUser = () => {



    return (
        <div className="process-wrapper">
          <div>
            <h1 className="process-title">
              È molto semplice iniziare a richiedere sovvenzioni
            </h1>
          </div>
          <div className="process-container">
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">1</div>
              <img src={prod1} alt="Crea il tuo account" style={{ maxWidth: "100%", height: "auto" }} />
              <p className="process-description-title">
                Crea il tuo account
              </p>
              <p className="process-description">
                Iniziamo fornendo solo un paio di informazioni di base
              </p>
            </div>
            <div className="process-box">
              <div className="process-number">2</div>
              <img src={prod2} alt="Aggiungi la partita iva" style={{ maxWidth: "100%", height: "auto" }} />
              <p className="process-description-title">Aggiungi i tuoi obiettivi strategici</p>
              <p className="process-description">
                Non preoccuparti, puoi sempre modificarli in seguito
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">3</div>
              <img src={prod3} alt="Ottenere le informazioni" style={{ maxWidth: "100%", height: "auto" }} />
              <p className="process-description-title">Ottenere le informazioni mirate per te</p>
              <p className="process-description">
                Cerca nel database storico gli incentivi personalizzati solo per te
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">4</div>
              <img src={prod4} alt="Ricevere aggiornamenti sui cambiamenti" style={{ maxWidth: "100%", height: "auto" }} />
              <p className="process-description-title">
                Ricevere aggiornamenti sui cambiamenti
              </p>
              <p className="process-description">
                Ogni settimana ti inviamo una newsletter personalizzata solo per te che ti informa dei nuovi incentivi
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">5</div>
              <img src={prod5} alt="Seleziona e Ricontrolla" style={{ maxWidth: "100%", height: "auto" }} />
              <p className="process-description-title">
                Seleziona e Ricontrolla
              </p>
              <p className="process-description">
                Dopo aver selezionato quello che desideri, utilizza il nostro software leader per qualificare la tua attività. Ottieni aiuto per qualsiasi domanda o dubbio.
              </p>
            </div>
            <div className="process-box" style={{ cursor: "pointer" }}>
              <div className="process-number">6</div>
              <img src={prod6} alt="Candidali" style={{ maxWidth: "100%", height: "auto" }} />
              <p className="process-description-title">
                Candidati
              </p>
              <p className="process-description">
                Se superi i criteri, avviamo la tua candidatura! Abbiamo tutto per realizzarlo. Dalle tempistiche, ai documenti necessari e al processo di invio.
              </p>
              <p className="process-description">
                Inizieremo utilizzando la nostra intelligenza artificiale leader dedicata a questo incentivo, per precompilare l'applicazione per te. Non preoccuparti, ti daremo la possibilità di cambiare o correggere tutto ciò che non sembra corretto. Alla fine ti verrà fornito tutto da caricare. Ti diremo dove andare e cosa puoi aspettarti. Ti terremo per mano durante tutto il percorso, così potrai essere sicuro che la tua richiesta venga completata in tempo.
              </p>
            </div>
          </div>
        </div>
      );
    };

export default AboutUser;